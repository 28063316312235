export function startLoading(){
    return{type:'LOADIND_START'};
}

export function endLoading(){
    return{type:'LOADIND_END'};
}

export function SnackBar(show,message,varient){
    console.log(show)
    console.log(message)
    console.log(varient)
    return {
                type:'SHOW_ALERT',
                show : show,
                message : message,
                varient:varient,
           } ;
}


export function ShowRemark(status,onOK,data){
    return {
            type:'SHOW_REMARK',
            status : status,
            onOK : onOK,
            data:data,
       }
}


export function handleHTTPError(response){
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}
