import React from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { TableBody } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchUser,updateUser} from '../../../Actions/UserActions';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { SnackBar } from '../../../Actions/CommonActions';
import Select from '@material-ui/core/Select';

  const mapDispatchToProps = dispatch => ({
    fetchUser: () => dispatch(fetchUser()),
     updateUser: (data) => dispatch(updateUser(data)),
     SnackBar : (show,message,varient) => dispatch(SnackBar(show,message,varient))
  });


  const mapStateToProps = (state) => {
  return {
    loading : state.Common.loading,
       loggedIn : state.Auth.loggedIn,
       user:state.Auth.user,
       userlist:state.User.userlist
  }
  }
const useStyles = makeStyles(theme => ({

  tableRow:{
    border: 'none',
  },

}));




class CSSGrid extends React.Component {

  constructor(props){
  super(props);

  this.state={
    fname:"",
    lname:"",
    phone:"",
    email:"",
    addrs:"",
    password:"",
    user_type:3

  }

  // this.props.fetchUser();

  }

  handleChange = name => event => {
  console.log(this.state.categories)


  this.setState({
  [name]: event.target.value,
  });

}

handleAdd = () =>{
  this.props.SnackBar(true,"Saving user",'success');

this.props.updateUser(
  {
    id:0,
    first_name:this.state.first_name,
    last_name:this.state.last_name,
    phone_number:this.state.phone,
    email:this.state.email,
    password:this.state.password,
    address:this.state.addrs,
    user_type:this.state.user_type
  }
);



}

handleClose=()=>{


  this.props.onClose()


}


handleSave = () =>{
  const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(this.state.first_name===undefined)
    {
      this.props.SnackBar(true,"First Name Can Not Be Empty",'error');
      return false
    }

    if(this.state.last_name===undefined)
      {
        this.props.SnackBar(true,"Last Name Can Not Be Empty",'error');
        return false
      }

    if(this.state.phone==="")
     {
      this.props.SnackBar(true,"phone Can Not Be Empty",'error');
       return false
     }

    if(this.state.email==="")
    {
      this.props.SnackBar(true,"Email Can Not Be Empty",'error');
      return false
    }

    if(reg.test(this.state.email) !== true){
      this.props.SnackBar(true,"Email not valid",'error');
      return false;
  }
    
    if(this.state.password==="")
      {
        this.props.SnackBar(true,"password Can Not Be Empty",'error');
        return false
      }
    
    if(this.state.addrs==="")
    {
      this.props.SnackBar(true,"Address Can Not Be Empty",'error');
      return false
    }
    this.handleAdd();
    this.props.onClose();
}




  render() {



  return (
  <div>
    <TableContainer>
        <Table size="small">
        <TableBody>
          <TableRow>
              <TableCell  >  <b> Permission </b> </TableCell>
              <TableCell  >
              <form  noValidate >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={this.state.user_type}
                  onChange={this.handleChange('user_type')}
                  labelWidth={170}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  {this.props.user !== null && this.props.user !== undefined && this.props.user.user_type == 1 ? <MenuItem value={1}>Super Admin</MenuItem> :null}
                  {this.props.user !== null && this.props.user !== undefined && this.props.user.user_type == 1 ? <MenuItem value={2}>Administrator</MenuItem> :null}
                  {this.props.user !== null && this.props.user !== undefined && (this.props.user.user_type == 1 || this.props.user[0].user_type == 2) ? <MenuItem value={3}>Operator</MenuItem> :null}



                </Select>
              </form>
              </TableCell>
          </TableRow>
        <TableRow>
            <TableCell  >  <b> First Name </b> </TableCell>
            <TableCell  >
            <form  noValidate >
                <TextField id="outlined-basic"value={this.state.first_name} onChange={this.handleChange('first_name')}    label="First Name" variant="outlined"size="small" />
            </form>
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell > </TableCell>
            <TableCell > </TableCell>
        </TableRow>

            <TableRow>
                <TableCell  >  <b>Last Name </b> </TableCell>
                <TableCell  >
                <form  noValidate >
                    <TextField id="outlined-basic" value={this.state.last_name} onChange={this.handleChange('last_name')}   label="Last Name" variant="outlined"size="small" />
                </form>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell  > </TableCell>
                <TableCell > </TableCell>
            </TableRow>
            <TableRow>
                <TableCell   ><b> Phone Number </b>   </TableCell>
                <TableCell  >
                <form  noValidate >
                    <TextField id="outlined-basic" value={this.state.phone} onChange={this.handleChange('phone')} label="Phone Number " variant="outlined"size="small" />
                </form>
                 </TableCell>
            </TableRow>
            <TableRow>
                <TableCell  > </TableCell>
                <TableCell  >  </TableCell>
            </TableRow>

            <TableRow>
                <TableCell  > <b> Email </b>  </TableCell>
                <TableCell  >
                <form  noValidate >
                    <TextField id="outlined-basic" value={this.state.email} onChange={this.handleChange('email')} label="email" variant="outlined"size="small" />
                </form>
                </TableCell>

            </TableRow>
            <TableRow>
                <TableCell  > <b> Password </b>  </TableCell>
                <TableCell  >
                <form  noValidate >
                    <TextField id="outlined-basic"  type="password" value={this.state.password} onChange={this.handleChange('password')} label="password" variant="outlined"size="small" />
                </form>
                </TableCell>

            </TableRow>
            <TableRow>
                <TableCell  > <b> Address </b>  </TableCell>
                <TableCell  >
                <form  noValidate >
                    <TextField id="outlined-basic" value={this.state.addrs} onChange={this.handleChange('addrs')}label="Address" variant="outlined"size="small" />
                </form>
                </TableCell>

            </TableRow>
            <TableRow>
                <TableCell  > </TableCell>
                <TableCell > </TableCell>

            </TableRow>

        </TableBody>
        </Table>
        </TableContainer>
        <Button autoFocus  color="primary"  onClick={()=>{this.handleSave();}} >
        Add
        </Button>
</div>
);
}
}


export default compose(connect(mapStateToProps, mapDispatchToProps))(CSSGrid);
