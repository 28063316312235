import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
//import PopUpDialig10 from './PopUp/editContactManagement';
import EditPopup from './EditPopup';

const useStyles = makeStyles(theme => ({
  button: {
    background: 'linear-gradient( #1976d2  , #1976d2  )',
    // backgroundColor:'linear-gradient( #1976d2  , #1976d2  )',
    color:'#ffffff',
    margin: theme.spacing(1),
  },
}));

export default function IconLabelButtons(props) {
  const classes = useStyles();
console.log(props.data);
  return (
    <div>


      <EditPopup data={props.data} />


    </div>
  );
}
