import React, {Fragment } from 'react';
import Sidebar  from '../SideContent/SideBarIndex';
import {makeStyles} from '@material-ui/core/styles';


import Tabs from './Tabs';
import Dashboard from './dash';
import Reply from '../ReceivedPackages/Common/reply';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
toolbar: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,

},
content: {
  alignItems: 'center',
  display: 'container',
  flexGrow: 1,
  padding: theme.spacing(3),

},
}));

export default function Archived() {
  const classes = useStyles();

    return (<Fragment>

      <div className={classes.root}>

        <Sidebar currentPage={"dashboard"}/>

        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div style={{padding:15,textAlign:'right'}}>
              <Reply/>
          </div>

          <Tabs/>
        </main>
        </div>

      </Fragment>
    );

}
