import React, {Fragment } from 'react';
import Sidebar  from '../SideContent/SideBarIndex';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import {showRecivedPackage,PackageStatus} from '../../Actions/PackageActions';
import { SnackBar } from '../../Actions/CommonActions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';


const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    alignItems: 'center',
    display: 'container',
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
    height:260,
  },
  paperRight: {
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    height:585,
  },
  paperBottom: {
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary, //change discription color
    height:290,
    marginTop:27,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  tableRow: {
    borderBottom: "none"
 },

 replies: {
  padding: theme.spacing(2),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  whiteSpace: 'wrap',
  marginBottom: theme.spacing(1),
  height:'auto',
  },

  }));


function mapStateToProps(state){
    console.log(state)
    return{
    list:state.Package.recivedPackageByID,
    contactlistFrom:state.Package.recivedPackageByID.from_contact,
    contactlistTo:state.Package.recivedPackageByID.to_contact,
    contactlist:state.Package.recivedPackageByID,
    user: state.Auth.user,
        }
  }

  const mapDispatchToProps = dispatch => ({
    showRecivedPackage: (id) => dispatch(showRecivedPackage(id)),
    PackageStatus: (id) => dispatch(PackageStatus(id)),
    SnackBar : (show,message,varient) => dispatch(SnackBar(show,message,varient))
    });

  function RecivedPackege(props) {
      console.log(props.list)
    const classes = useStyles();
    const [check,setCheck]=React.useState(true)
    const { id } = props.match.params;
    console.log(id)

    if(check===true)
        {

            props.SnackBar(true,"Data is loading...",'success');
            props.showRecivedPackage(id)
            setCheck(false)
        }


  const handleStatus = () => {
    props.PackageStatus(id)
    props.SnackBar(true,"Updating package status...",'success');
  };

      return (<Fragment>

        <div className={classes.root}>

          <Sidebar currentPage={"inbox"}/>

          <main className={classes.content}>
            <div className={classes.toolbar} />
              <TableContainer>
                <Table size="small">
                <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableRow}> <Typography variant="h4" align="left">Received Packages</Typography> </TableCell>
                        <TableCell className={classes.tableRow} align="right">

                            {props.contactlistTo.email === props.user.email ? <Button variant="contained" color="primary" onClick={()=>handleStatus()} >
                                       Delivered
                            </Button>:null}


                      </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
              </TableContainer>
              <div>
                  {/* TWO SIDE  */}
                  <Grid container spacing={1}>
                    {/* LEFT */}
                  <Grid item xs={12} sm={8}>
                    {/* TWO PAGES */}
                        <Grid container spacing={1}>

                              <Grid item xs={6}>
                                  <Paper className={classes.paper}>
                                    <TableContainer>
                                      <Table size="small">
                                      <TableHead>
                                          <TableRow>
                                            <TableCell> <Typography variant="h6" align="left"> From </Typography> </TableCell>
                                            <TableCell align="right"></TableCell>
                                        </TableRow>
                                      </TableHead>
                                    </Table>
                                    </TableContainer>

                                    <TableContainer>
                                      <Table size="small">
                                      <TableBody>
                                          <TableRow>
                                              <TableCell className={classes.tableRow} >  <b> Name </b> </TableCell>
                                              <TableCell className={classes.tableRow} >
                                              <form  noValidate >

                                                  <p>{props.contactlistFrom !== null ? props.contactlistFrom.first_name: "" }{ }{props.contactlistFrom !== null ? props.contactlistFrom.last_name:""}</p>
                                              </form>
                                              </TableCell>
                                          </TableRow>
                                          <TableRow>
                                              <TableCell className={classes.tableRow} > </TableCell>
                                              <TableCell className={classes.tableRow} > </TableCell>
                                          </TableRow>
                                          <TableRow>
                                              <TableCell className={classes.tableRow}  ><b> Phone Number </b>   </TableCell>
                                              <TableCell className={classes.tableRow} >
                                              <form  noValidate >
                                                    <p> {props.contactlistFrom !== null ?props.contactlistFrom.phone_number:""} </p>
                                              </form>
                                              </TableCell>
                                          </TableRow>
                                          <TableRow>
                                              <TableCell className={classes.tableRow} > </TableCell>
                                              <TableCell className={classes.tableRow} >  </TableCell>
                                          </TableRow>
                                          <TableRow>
                                              <TableCell className={classes.tableRow} > <b> Address </b>  </TableCell>
                                              <TableCell className={classes.tableRow} >
                                              {props.contactlistFrom !== null ?props.contactlistFrom.address:""}
                                              </TableCell>

                                          </TableRow>
                                          <TableRow>
                                              <TableCell className={classes.tableRow} > </TableCell>
                                              <TableCell className={classes.tableRow} >  </TableCell>
                                          </TableRow>
                                      </TableBody>
                                      </Table>
                                      </TableContainer>

                                  </Paper>
                              </Grid>

                              <Grid item xs={6}>
                                  <Paper className={classes.paper}>
                                    <TableContainer>
                                      <Table size="small">
                                      <TableHead>
                                          <TableRow>
                                            <TableCell> <Typography variant="h6" align="left"> To </Typography> </TableCell>
                                            <TableCell align="right"></TableCell>
                                        </TableRow>
                                      </TableHead>
                                    </Table>
                                    </TableContainer>
                                      <TableContainer>
                                            <Table size="small">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell className={classes.tableRow} >  <b> Name</b> </TableCell>
                                                    <TableCell className={classes.tableRow} >
                                                    <form  noValidate >

                                                        <p>{props.contactlistTo !== null ? props.contactlistTo.first_name: "" }{ }{props.contactlistTo !== null ? props.contactlistTo.last_name:""}</p>
                                                    </form>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.tableRow} > </TableCell>
                                                    <TableCell className={classes.tableRow} > </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.tableRow}  ><b> Phone Number </b>   </TableCell>
                                                    <TableCell className={classes.tableRow} >
                                                    <form  noValidate >
                                                          <p> {props.contactlistTo !== null ? props.contactlistTo.phone_number:""} </p>
                                                    </form>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.tableRow} > </TableCell>
                                                    <TableCell className={classes.tableRow} >  </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.tableRow} > <b> Address </b>  </TableCell>
                                                    <TableCell className={classes.tableRow} >
                                                    {props.contactlistTo !== null ? props.contactlistTo.address:""}
                                                    </TableCell>

                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.tableRow} > </TableCell>
                                                    <TableCell className={classes.tableRow} >  </TableCell>
                                                </TableRow>
                                            </TableBody>
                                            </Table>
                                            </TableContainer>
                                  </Paper>
                              </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper className={classes.paperBottom}>
                              <TableContainer>
                                <Table size="small">
                                <TableHead>
                                    <TableRow>
                                      <TableCell> <Typography variant="h6" align="left">  Tracking Code </Typography> </TableCell>
                                      <TableCell align="right"></TableCell>
                                  </TableRow>
                                </TableHead>
                              </Table>
                              </TableContainer>
                              <div  style={{ marginTop:65 }}>
                                <Typography variant="h3" style={{ padding:20, textAlign:"center",   }}>
                                {props.list.tracking_code}
                                </Typography>
                              </div>
                              <TableContainer>
                                <Table size="small">
                                <TableHead>
                                    <TableRow>
                                      <TableCell className={classes.tableRow}> </TableCell>
                                      <TableCell className={classes.tableRow} align="right">  </TableCell>
                                  </TableRow>
                                </TableHead>
                              </Table>

                              </TableContainer>

                          </Paper>
                        </Grid>
                  </Grid>



                  <Grid item xs={12} sm={4}>
                    <Paper className={classes.paperRight}>
                      <TableContainer>
                        <Table size="small">
                        <TableHead>
                            <TableRow>
                              <TableCell> <Typography variant="h6" align="left"> Content </Typography> </TableCell>
                              <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                      <TableRow>
                           {props.list.contains.map((data)=>{
                              return <TableRow><TableCell className={classes.tableRow}><li><span style={{minWidth:150,maxWidth:150,whiteSpace:'nowrap',fontSize:16 }}> {data} </span></li></TableCell></TableRow>
                            })}
                        </TableRow>
                      </TableBody>
                      </Table>
                      </TableContainer>
                    </Paper>
                  </Grid>

                  <Grid item xs={9}>
                    <div>
                      <h1> Tracking History </h1>
                    </div>

                        <div className={classes.root}>
                        <div className={classes.content} style={{ marginLeft:70 }}>
                        </div>
                        </div>
                    {props.contactlist.trail.map((data)=>{
                    return<Fragment>
                      <Paper className={classes.replies}>
                      <Typography variant="h5"> {data.created_at}</Typography>
                        <Typography variant="body1" className={classes.replies}  style={{ padding:5}} >
                        {data.user.first_name}{" "}{data.user.last_name}
                        </Typography>
                        <Typography variant="body1" className={classes.replies}  style={{ padding:5 }} >
                        {data.user.email}
                        </Typography>
                        <Typography variant="body1" className={classes.replies}  style={{ padding:5 }} >
                        {data.user.phone_number}
                        </Typography>
                        <Typography variant="body1" className={classes.replies}  style={{ padding:5 }} >
                        {data.user.address}
                      </Typography>
                      </Paper>
                    </Fragment>
                        })}

                  </Grid>

                </Grid>
              </div>

          </main>

          </div>

        </Fragment>
      );

  }

  export default compose(connect(mapStateToProps, mapDispatchToProps))(RecivedPackege);
