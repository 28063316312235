import React from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { TableBody } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
//import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({

  tableRow: {
    borderBottom: "none"
 }, 

}));

export default function CSSGrid() {
  const classes = useStyles();

  return (

    <TableContainer>
        <Table size="small">
        <TableBody>
            <TableRow>
                <TableCell className={classes.tableRow} > <center><b> Are You Sure ?</b></center></TableCell>
                <TableCell className={classes.tableRow} >
                <form  noValidate >

                </form>
                </TableCell>
            </TableRow>





        </TableBody>
        </Table>
        </TableContainer>
);

}
