import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import IconButton from '@material-ui/core/IconButton';
import EditButton from'./EditButton';
import DeleteButton from './DeleteButton';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { fetchUser, DeleteUser } from '../../../Actions/UserActions';


const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUser()),
   DeleteUser: (id) => dispatch(DeleteUser(id)),
});

const mapStateToProps = (state) => {
return {
  loading : state.Common.loading,
     loggedIn : state.Auth.loggedIn,
     userlist:state.User.userList
}
}

class CSSGrid extends React.Component {

  constructor(props){
  super(props);

  this.state={
    fname:"",
    lname:"",
    phone:"",
    email:"",
    addrs:""


  }

  this.props.fetchUser();

  }

  handleChange = name => event => {
 console.log(this.state.categories)


 this.setState({
 [name]: event.target.value,
 });

}

handleDelete = (id) =>{
  console.log(id)


this.props.DeleteUser(id);

}



render() {

  return (

    <Table size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
        <TableCell>No</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Address</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
        </TableRow>
      </TableHead>
  {this.props.userlist !== undefined && this.props.userlist !== null  ? this.props.userlist.map((row,index) => (
      <TableBody>

          <TableRow key={row.id}>
           <TableCell>{index+1}</TableCell>
           <TableCell>{row!==null ?row.first_name:null}</TableCell>
           <TableCell>{row!==null ?row.last_name:null}</TableCell>
           <TableCell>{row!==null ?row.phone_number:null}</TableCell>
             <TableCell>{row!==null ?row.email:null}</TableCell>
           <TableCell>{row!==null ?row.address:null}</TableCell>


            <TableCell align="right"  >
              <EditButton   data={row}/>
            </TableCell>

            <TableCell align="left"  >
                <Button  > 
                  <DeleteButton handleDelete={()=>this.handleDelete(row.id)} />
            </Button>

            </TableCell >
          </TableRow>
     </TableBody>
 
   )):null}

    </Table>

  );
}
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(CSSGrid);
