import React, {Fragment, useState } from 'react';
import Sidebar  from '../SideContent/SideBarIndex';

import {makeStyles,withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import TableContactManagement from './Common/ContactList';

import AddContactButton from './Common/AddContactButton';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

import { fetchContact,DeleteContact } from '../../Actions/ContactActions';


const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop:150,
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar

  },
  content: {
    alignItems: 'center',
    display: 'container',
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop:150
  },
  }));

  const mapDispatchToProps = dispatch => ({
    fetchContact: () => dispatch(fetchContact()),
     DeleteContact: (id) => dispatch(DeleteContact(id)),
  });

  const mapStateToProps = (state) => {
  return {
    loading : state.Common.loading,
       loggedIn : state.Auth.loggedIn,
       contactlist:state.Contact.contactlist
  }
  }

  class CSSGrid extends React.Component {

    componentDidMount = () =>{
          this.props.fetchContact();
    }

    constructor(props){
    super(props);

    this.state={
      fname:"",
      lname:"",
      phone:"",
      email:"",
      addrs:""


    }

    this.props.fetchContact();
  }

  handleChange = name => event => {
  console.log(this.state.categories)


  this.setState({
  [name]: event.target.value,
  });

  }

  handleDelete = (id) =>{
  this.props.DeleteContact(id);

  }

  render() {

    const {classes} = this.props;

    return (<Fragment>

      <div className={classes.root}>
          <Sidebar currentPage={"Contact"}/>



        <main className={classes.content} style={{paddingTop:100,paddingLeft:100}}>
          <div className={classes.toolbar} />

            <TableContainer>
              <Table size="small">
              <TableHead>
                  <TableRow>

                  <TableCell className={classes.tableRow}> <Typography variant="h4" align="left"> Contacts</Typography></TableCell>
                   <TableCell className={classes.tableRow} align="right"> <AddContactButton/> </TableCell>

                </TableRow>
              </TableHead>
            </Table>
            </TableContainer>
            <div>
                <Grid container spacing={1}>

                <Grid item xs={12} sm={8}>

                      <Grid container spacing={1}>

                            <Grid item xs={6}>


                            </Grid>
                      </Grid>
                </Grid>

              </Grid>
            </div>
            <TableContactManagement/>

        </main>
        </div>

      </Fragment>
    );
}
}

export default compose(withStyles(useStyles),connect(mapStateToProps, mapDispatchToProps))(CSSGrid);
