import {API_URL} from './Config';
import {startLoading, endLoading, SnackBar, handleHTTPError} from './CommonActions';
import store from '../Store';

export function fetchUser() {

  return function(dispatch) {
    dispatch(startLoading());
    fetch(API_URL + 'api/users/list', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
      }
    }).then(handleHTTPError).then((response) => {
      response.json().then((json) => {
        dispatch(storeUsers(json));
        dispatch(SnackBar(true,'User Loading..','info'));
        dispatch(endLoading());
      });

    });

  }
}

export function storeUsers(user) {
  return {type: 'STORE_USERS', user: user};
}

export function updateUser(data) {

  return function(dispatch) {
    dispatch(startLoading());
    fetch(API_URL + 'api/users/update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
      },
      body: JSON.stringify(data)
    }).then(handleHTTPError).then((response) => {
      dispatch(endLoading());
      response.json().then((json) => {
        dispatch(endLoading());
        dispatch(fetchUser(json)); 
        dispatch(SnackBar(true, 'Successfully saved', 'success'));
      });
    }).catch((error) => {
      dispatch(endLoading());
      dispatch(SnackBar(true, 'Could not save data', 'error'));
    });
  }

}

export function DeleteUser(id) {
  console.log(id)

  return function(dispatch) {
    dispatch(startLoading());
    fetch(API_URL + '/api/users/delete?id=' + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
      }
    }).then(handleHTTPError).then((response) => {
      dispatch(endLoading());
      dispatch(fetchUser()); 
      dispatch(SnackBar(true, 'Successfully Deleted', 'success'));
    }).catch((error) => {
      dispatch(endLoading());
      dispatch(SnackBar(true, 'Could not Delete data', 'error'));
    });
  }

}
