import React, {Fragment } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {showPackage} from '../../../Actions/PackageActions';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      width:700
    },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    alignItems: 'center',
    display: 'container',
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  tableRow:{
    border: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
    height:'auto',
    width:700,
  },
  }));


function mapStateToProps(state){
  return{
    data:state.User.activePackage,
    PintTo:state.User.activePackage.to_contact,
    PintFrom:state.User.activePackage.from_contact
  }
}

const mapDispatchToProps = dispatch => ({
  showPackage:(id)=>dispatch(showPackage(id))
  });

 function Inbox(props) {
  const classes = useStyles();
   console.log(props.data)


    //const [id,setId]=React.useState(true)

    const { id } = props.match.params;
      if(id !== null && props.data.id === 0 )
      {
          props.showPackage(id)
          //setId(false)
      }

      return (<Fragment>

        <div className={classes.root}>

          <main className={classes.content}>

            <div>

            <TableContainer>

                <Table size="small">
                <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableRow}>
                        <table>
                          <thead>
                          <tr>
                          <td> <img src={props.data.qr_code} /><h3 style={{fontSize:36}}>{props.data.tracking_code}</h3></td>
                          </tr>
                            <tr>
                                <td> <h1></h1></td>
                             </tr>
                          </thead>
                        </table>
                      </TableCell>

                  </TableRow>
                </TableHead>
              </Table>
              </TableContainer>

              <div>
                  {/* TWO SIDE  */}

                    {/* LEFT */}
                  <Grid item xs={12} sm={13}>
                    {/* TWO PAGES */}
                        <Grid container spacing={1}>
                          {/* PAGE 1  DemographyTable*/}
                              <Grid item xs={6}>
                                  <div style={{border:"1px solid #000", minHeight:281}}>
                                    <TableContainer>
                                      <Table size="small">
                                      <TableHead>
                                          <TableRow>
                                            <TableCell> <Typography variant="h6" align="left"> From </Typography> </TableCell>
                                            <TableCell align="right"></TableCell>
                                         </TableRow>
                                      </TableHead>
                                    </Table>
                                    </TableContainer>
                                      <TableContainer>

                                          <Table size="small">
                                          <TableBody>
                                              <TableRow>
                                                  <TableCell className={classes.tableRow} >  <b> Name </b> </TableCell>
                                                  <TableCell className={classes.tableRow} >
                                                  <form  noValidate >
                                                  <p>{props.PintFrom !== null ? props.PintFrom.first_name:""}{}{props.PintFrom !== null ? props.PintFrom.last_name:""}  </p>
                                                  </form>
                                                  </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                  <TableCell className={classes.tableRow} > </TableCell>
                                                  <TableCell className={classes.tableRow} > </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                  <TableCell className={classes.tableRow}  ><b> Phone Number </b>   </TableCell>
                                                  <TableCell className={classes.tableRow} >
                                                  <form  noValidate >
                                                    <p>{props.PintFrom !== null ? props.PintFrom.phone_number:""}</p>
                                                  </form>
                                                  </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                  <TableCell className={classes.tableRow} > </TableCell>
                                                  <TableCell className={classes.tableRow} >  </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                  <TableCell className={classes.tableRow} > <b> Address </b>  </TableCell>
                                                  <TableCell className={classes.tableRow} >
                                                  <form  noValidate >
                                                        <p>{props.PintFrom !== null ? props.PintFrom.address:""}</p>
                                                  </form>
                                                  </TableCell>

                                              </TableRow>
                                              <TableRow>
                                                  <TableCell className={classes.tableRow} > </TableCell>
                                                  <TableCell className={classes.tableRow} >  </TableCell>

                                              </TableRow>
                                          </TableBody>
                                          </Table>
                                          </TableContainer>
                                  </div>
                              </Grid>
                              {/* PAGE 2  DoctorTable*/}
                              <Grid item xs={6}>
                                  <div style={{border:"1px solid #000", minHeight:281}}>
                                    <TableContainer>
                                      <Table size="small">
                                      <TableHead>
                                          <TableRow>
                                            <TableCell> <Typography variant="h6" align="left"> To </Typography> </TableCell>
                                            <TableCell align="right"></TableCell>
                                        </TableRow>
                                      </TableHead>
                                    </Table>
                                    </TableContainer>


                                                  <TableContainer>

                                                    <Table size="small">
                                                    <TableBody>

                                                        <TableRow>

                                                            <TableCell className={classes.tableRow} >  <b> Name </b> </TableCell>
                                                            <TableCell className={classes.tableRow} >
                                                            <form  noValidate >
                                                                <p>{props.PintTo !== null ? props.PintTo.first_name:""}{}{props.PintTo !== null ? props.PintTo.last_name:" "} </p>
                                                            </form>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell className={classes.tableRow} > </TableCell>
                                                            <TableCell className={classes.tableRow} > </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell className={classes.tableRow}  ><b> Phone Number </b>   </TableCell>
                                                            <TableCell className={classes.tableRow} >
                                                            <form  noValidate >
                                                              <p>{props.PintTo !== null ? props.PintTo.phone_number:""}</p>
                                                            </form>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell className={classes.tableRow} > </TableCell>
                                                            <TableCell className={classes.tableRow} >  </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell className={classes.tableRow} > <b> Address </b>  </TableCell>
                                                            <TableCell className={classes.tableRow} >
                                                            <form  noValidate >
                                                              <p>{props.PintTo !== null ? props.PintTo.address:""}</p>
                                                            </form>
                                                            </TableCell>

                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell className={classes.tableRow} > </TableCell>
                                                            <TableCell className={classes.tableRow} >  </TableCell>

                                                        </TableRow>
                                                    </TableBody>
                                                    </Table>
                                                    </TableContainer>

                                  </div>
                              </Grid>
                        </Grid>
                  </Grid>

                  <div style={{border:"1px solid #000",marginTop:15}}>
                    <TableContainer>
                      <Table size="small">
                      <TableHead>
                          <TableRow>
                            <TableCell> <Typography variant="h6" align="left"> Contains </Typography> </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      <TableRow>
                           {props.data.contains.map((data)=>{
                              return <TableRow><TableCell className={classes.tableRow}><li><span style={{minWidth:150,maxWidth:150,whiteSpace:'nowrap',fontSize:16 }}> {data} </span></li></TableCell></TableRow>
                            })}
                        </TableRow>
                      </TableBody>
                    </Table>
                    </TableContainer>
                  </div>
              </div>
              </div>
          </main>
          </div>
        </Fragment>
      );

  }

  export default compose(connect(mapStateToProps, mapDispatchToProps))(Inbox);
