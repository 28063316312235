import {API_URL} from './Config';
import {startLoading,endLoading,SnackBar,handleHTTPError} from './CommonActions';
import store from '../Store';

 

    export function fetchContact(){

        return function (dispatch) {
            dispatch(startLoading());
            fetch(API_URL + 'api/contacts/list', {
            method: 'GET',
                headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
                }
             }).then(handleHTTPError)
            .then((response) => {
            response.json().then((json) => {
            dispatch(SnackBar(true,'Loading contacts...','success'));
            dispatch(storeContact(json));
            dispatch(endLoading());
           
            });

            });

        }
    }

export function storeContact(con) {
return { type: 'STORE_CONTACT', con : con };
}


export function fetchContactFrom(){
console.log("refefdchsdvcfhj")
    return function (dispatch) {
        dispatch(startLoading());
        fetch(API_URL + 'api/contacts/list', {
        method: 'GET',
            headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
            }
         }).then(handleHTTPError)
        .then((response) => {
        response.json().then((json) => {
        dispatch(storeContactFrom(json));
        dispatch(endLoading());
        });

        });

    }
}


export function storeContactFrom(con) {
    console.log(con)
       return { type: 'STORE_ADDED_CONTACT_FROM_FULL', con : con };
}

export function storeAddedContact(con){
    return{type:'STORE_ADDED_CONTACT_FROM',From:con};
}

export function storeAddedContactTo(con){
    return{type:'STORE_ADDED_CONTACT_TO',To:con};
}


export function storePackageAllData(Data){
    console.log(Data)
    return{type:'STORE_PACKAGE_ALL_DATA',all:Data};
}


export function updateContact(data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'api/contacts/update',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
                dispatch(endLoading());
                response.json().then((json)=>{
                    dispatch(endLoading());
                    dispatch(fetchContact(json));
                    dispatch(SnackBar(true,'Successfully saved','success'));
                });
        }).catch((error)=>{
            dispatch(SnackBar(true,'Could not save data','error'));
            dispatch(endLoading());
        });
    }

}


export function fetchContent(){

    return function (dispatch) {
        dispatch(startLoading());
        fetch(API_URL + 'api/package/update', {
        method: 'POST',
            headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
            }
         }).then(handleHTTPError)
        .then((response) => {
        response.json().then((json) => {
        dispatch(storeContent(json));
        dispatch(endLoading());
        });

        });

    }
}


export function storeContent(content) {
    return { type: 'STORE_CONTENT', content : content };
}


    export function DeleteContact(id) {
        return function(dispatch) {
                dispatch(startLoading());
                    fetch(API_URL+'/api/contacts/delete?id='+id,{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                        'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
        },
        }).then(handleHTTPError)
        .then((response)=>{
            dispatch(endLoading());
            dispatch(fetchContact()); 
            dispatch(SnackBar(true,'Successfully Deleted','success'));
            }).catch((error)=>{
            dispatch(endLoading());
            dispatch(SnackBar(true,'Could not Delete data ','error'));
            });
        }

  }
