import React from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { TableBody } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchUser,updateUser} from '../../../Actions/UserActions';
import { SnackBar } from '../../../Actions/CommonActions';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUser()),
  updateUser: (data) => dispatch(updateUser(data)),
  SnackBar : (show,message,varient) => dispatch(SnackBar(show,message,varient))
});


 const mapStateToProps = (state) => {
 return {
   loading : state.Common.loading,
   loggedIn : state.Auth.loggedIn,
   userlist:state.User.userlist,
   user:state.Auth.user,
 }
 }


const useStyles = makeStyles(theme => ({

  tableRow: {
    borderBottom: "none"
 },

}));

 class CSSGrid extends React.Component {

  constructor(props){
  super(props);
//const { data } = this.props;
  this.state={
    first_name:this.props.data.first_name,
    last_name:this.props.data.last_name,
    phone:this.props.data.phone_number,
    email:this.props.data.email,
    address:this.props.data.address,
    password:"",
    user_type:3

  }

//  this.props.fetchUser();

  }

  handleChange = name => event => {
  console.log(this.state.categories)


  this.setState({
  [name]: event.target.value,
  });

}

componentDidMount(){
  this.setState({
    fname:this.props.data.first_name,
    lname:this.props.data.last_name,
    phone:this.props.data.phone_number,
    email:this.props.data.email,
    address:this.props.data.address,
    user_type:this.props.data.user_type
  })
}

handleAdd = () =>{

  this.props.SnackBar(true,"Updating user",'success');
this.props.updateUser(
  {
    id:this.props.data.id,
    first_name:this.state.first_name,
    last_name:this.state.last_name,
    phone_number:this.state.phone,
    email:this.state.email,
    address:this.state.address,
    password:this.state.password,
    user_type:this.state.user_type
  }
);
}

handleClose=()=>{
    this.props.onClose()
}

handleSave = () => {
  this.handleAdd();
  this.props.onClose()
}

render() {

console.log(this.props.data.first_name)


  return (
<div>
    <TableContainer>
        <Table size="small">
        <TableBody>
          <TableRow>
              <TableCell  >  <b> Permission </b> </TableCell>
              <TableCell  >
              <form  noValidate >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={this.state.user_type}
                  onChange={this.handleChange('user_type')}
                  labelWidth={170}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  {this.props.user !== null && this.props.user !== undefined && this.props.user.user_type == 1 ? <MenuItem value={1}>Super Admin</MenuItem> :null}
                  {this.props.user !== null && this.props.user !== undefined && this.props.user.user_type == 1 ? <MenuItem value={2}>Administrator</MenuItem> :null}
                  {this.props.user !== null && this.props.user !== undefined && (this.props.user.user_type == 1 || this.props.user[0].user_type == 2) ? <MenuItem value={3}>Operator</MenuItem> :null}



                </Select>
              </form>
              </TableCell>
          </TableRow>
            <TableRow>
                <TableCell  >  <b> First Name</b> </TableCell>
                <TableCell  >
                <form  noValidate >
                    <TextField id="outlined-basic" value={this.state.first_name} onChange={this.handleChange('first_name')}  label="First Name"  variant="outlined"size="small" />
                </form>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell  >  <b> Last Name</b> </TableCell>
                <TableCell  >
                <form  noValidate >
                    <TextField id="outlined-basic" value={this.state.last_name} onChange={this.handleChange('last_name')}  label="Last Name" variant="outlined"size="small" />
                </form>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell  > </TableCell>
                <TableCell  > </TableCell>
            </TableRow>
            <TableRow>
                <TableCell  ><b> Phone Number </b>   </TableCell>
                <TableCell  >
                <form  noValidate >
                    <TextField id="outlined-basic"value={this.state.phone} onChange={this.handleChange('phone')}  label="Phone Number " variant="outlined"size="small" />
                </form>
                 </TableCell>
            </TableRow>
            <TableRow>
                <TableCell  > </TableCell>
                <TableCell >  </TableCell>
            </TableRow>
            <TableRow>
                <TableCell  >  <b> Email</b> </TableCell>
                <TableCell  >
                <form  noValidate >
                    <TextField id="outlined-basic" value={this.state.email} onChange={this.handleChange('email')} label="Email" variant="outlined"size="small" />
                </form>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell  > <b> Password </b>  </TableCell>
                <TableCell  >
                <form  noValidate >
                    <TextField id="outlined-basic"  type="password" value={this.state.password} onChange={this.handleChange('password')} label="password" variant="outlined"size="small" />
                </form>
                </TableCell>

            </TableRow>
            <TableRow>
                <TableCell > <b> Address </b>  </TableCell>
                <TableCell  >
                <form  noValidate >
                    <TextField id="outlined-basic" value={this.state.address} onChange={this.handleChange('address')} label="Address" variant="outlined"size="small" />
                </form>
                </TableCell>

            </TableRow>
            <TableRow>
                <TableCell  > </TableCell>
                <TableCell > </TableCell>

            </TableRow>

        </TableBody>
        </Table>
        </TableContainer>
        <Button autoFocus  color="primary" onClick={()=>{this.handleSave();}}>
            Save
        </Button>
</div>
);
}
}
  export default compose(connect(mapStateToProps, mapDispatchToProps))(CSSGrid);
