import {API_URL} from './Config';
import {startLoading, endLoading,SnackBar, handleHTTPError} from './CommonActions';
import store from '../Store';

export function fetchPackage() {
  return function(dispatch) {
    console.log("action sent")
    dispatch(startLoading());
    fetch(API_URL + 'api/package/list?box=sent', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
      }
    }).then(handleHTTPError).then((response) => {
      response.json().then((json) => {
       dispatch(storeSentPackage(json));
       dispatch(SnackBar(true,'Loading packages..','success'));
        console.log(json)
        dispatch(endLoading());
      });

    });
  }
}
export function storeSentPackage(pack) {
  console.log(pack)
  return {type: 'STORE_SENT_PACKAGES', package: pack};
}


export function fetchMyPackage() {
  return function(dispatch) {
    console.log("action sent")
    dispatch(startLoading());
    fetch(API_URL + 'api/package/list?box=mine', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
      }
    }).then(handleHTTPError).then((response) => {
      response.json().then((json) => {
       dispatch(storeMyPackage(json));
       dispatch(SnackBar(true,'Loading packages..','success'));
        dispatch(endLoading());
      });

    });
  }
}
export function storeMyPackage(pack) {
  console.log(pack)
  return {type: 'STORE_RECEIVED_MY_PACKAGES', package: pack};
}


export function fetchReceivedPackage() {
  return function(dispatch) {
    console.log("action sent")
    dispatch(startLoading());
    fetch(API_URL + 'api/package/list?box=inbox', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
      }
    }).then(handleHTTPError).then((response) => {
      response.json().then((json) => {
        dispatch(storeReceivedPackage(json));
        dispatch(SnackBar(true,'Packages loaded..','success'));
        console.log(json)
        dispatch(endLoading());
      });

    });
  }
}
export function storeReceivedPackage(pack) {
  console.log(pack)
  return {type: 'STORE_RECEIVED_PACKAGESS', packageReceived: pack};
}

export function addPackage(data) {

  return function(dispatch) {
    dispatch(startLoading());
    fetch(API_URL + 'api/package/update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
      },
      body: JSON.stringify(data)
    }).then(handleHTTPError).then((response) => {
      dispatch(endLoading());
      response.json().then((json) => {
        dispatch(endLoading());
        dispatch(allPackageData(json));
        dispatch(SnackBar(true,'Successfully created','success'));
      });
    }).catch((error) => {
      dispatch(endLoading());
      dispatch(SnackBar(true,'Could not save data','error'));
    });
  }

}

export function allPackageData(pack) {
  return {type: 'All_PACKAGE_DATA', Pack: pack};
}

export function showPackage(id) {

  return function(dispatch) {
    dispatch(startLoading());
    fetch(API_URL + 'api/package/show?id=' + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token        
      }
    }).then(handleHTTPError).then((response) => {
      dispatch(endLoading());
      response.json().then((json) => {
        dispatch(endLoading());
        dispatch(ShowPackageData(json));
      });
    }).catch((error) => {
      dispatch(endLoading());
    });
  }

}

export function ShowPackageData(pack) {
  console.log(pack)
  return {type: 'SHOW_PACKAGE_DATA', Pack: pack};
}

export function recievedPackage(data) {

  return function(dispatch) {
    dispatch(startLoading());
    fetch(API_URL + 'api/package/trail/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
      },
      body: JSON.stringify(data)
    }).then(handleHTTPError).then((response) => {
      dispatch(endLoading());
      response.json().then((json) => {
        dispatch(endLoading());
       // dispatch(SnackBar(true,'Packages updating..','success'));
        dispatch(fetchReceivedPackage());
      });
    }).catch((error) => {
      dispatch(SnackBar(true,'Packages Code Not Available','error'));
      dispatch(endLoading());

    });
  }

}

export function showInboxPackage(id) {

  return function(dispatch) {
    dispatch(startLoading());
    fetch(API_URL + 'api/package/show?id=' + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
      }
    }).then(handleHTTPError).then((response) => {
      dispatch(endLoading());
      response.json().then((json) => {
        dispatch(endLoading());
        dispatch(ShowInboxPackageData(json));
      });
    }).catch((error) => {
      dispatch(endLoading());
    });
  }

}

export function ShowInboxPackageData(InboxPack) {
  return {type: 'SHOW_INBOX_PACKAGE_DATA', Pack: InboxPack};
}

export function showRecivedPackage(id) {

  return function(dispatch) {
    dispatch(startLoading());
    fetch(API_URL + 'api/package/show?id=' + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
      }
    }).then(handleHTTPError).then((response) => {
      dispatch(endLoading());
      response.json().then((json) => {
        dispatch(endLoading());
        dispatch(ShowRecivedPackageData(json));
      });
    }).catch((error) => {
      dispatch(endLoading());
    });
  }

}

export function ShowRecivedPackageData(Pack) {
  return {type: 'SHOW_RECIVED_PACKAGE_DATA', Pack: Pack};
}


export function PackageStatusSent(id) {

  return function(dispatch) {
    dispatch(startLoading());
    fetch(API_URL + 'api/package/status/update?id=' + id + '&status=1', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
      },
      body: JSON.stringify()
    }).then(handleHTTPError).then((response) => {
      dispatch(endLoading());
      response.json().then((json) => {
        dispatch(endLoading());
        dispatch(fetchPackage());
      });
    }).catch((error) => {
      dispatch(endLoading());
    });
  }

}

export function PackageStatus(id) {

  return function(dispatch) {
    dispatch(startLoading());
    fetch(API_URL + 'api/package/status/update?id=' + id + '&status=3', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
      },
      body: JSON.stringify()
    }).then(handleHTTPError).then((response) => {
      dispatch(endLoading());
      response.json().then((json) => {
        dispatch(endLoading());
        dispatch(fetchReceivedPackage());
      });
    }).catch((error) => {
      dispatch(endLoading());
    });
  }

}

export function showToPrintPackage(id) {

  return function(dispatch) {
    dispatch(startLoading());
    fetch(API_URL + 'api/package/show?id=' + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
      }
    }).then(handleHTTPError).then((response) => {
      dispatch(endLoading());
      response.json().then((json) => {
        dispatch(endLoading());
        dispatch(PrintPackageData(json));
      });
    }).catch((error) => {
      dispatch(endLoading());
    });
  }

}

export function PrintPackageData(Pack) {
  console.log("here is the code")
  console.log(Pack)
  return {type: 'PRINT_PACKAGE_DATA', Pack: Pack};
}
