import React, {Fragment,useEffect } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {showToPrintPackage} from '../../../Actions/PackageActions';
import Button from '@material-ui/core/Button';
import './print.css';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      width:700
    },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    alignItems: 'center',
    display: 'container',
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  tableRow:{
    border: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
    height:'auto',
    width:700,
  },
  }));


function mapStateToProps(state){
    console.log(state)
  return{
    data:state.Package.PrintPackageByID,
    myPackPrintFrom:state.Package.PrintPackageByID.from_contact,
    myPackPrintTo:state.Package.PrintPackageByID.to_contact
  }
}

const mapDispatchToProps = dispatch => ({
    showToPrintPackage:(id)=>dispatch(showToPrintPackage(id))
  });

 function Inbox(props) {
   console.log(props.data)

    const classes = useStyles();
    const { id } = props.match.params;
    const [relode,setRelode]=React.useState(true)
    console.log(id)

      if(id !== null&&relode===true)
      {
          props.showToPrintPackage(id)
          setRelode(false)
      }

      useEffect(() => {
          if(props.data !== undefined && props.data !== null && props.data.tracking_code !== null ){
              setTimeout(()=>{
                window.print();
              },1000)
          }
      });



      return (<Fragment>

        <div className={classes.root}>

          <main className={classes.content}>
            <div className="print-button" >
                <Button size="small" variant="contained" color="primary"  onClick={()=>window.print()}>Print</Button>
            </div>
            <div>

            <TableContainer>

                <Table size="small">
                <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableRow}>
                        <table>
                          <thead>
                          <tr>
                          <td> <img src={props.data.qr_code} /><h3 style={{fontSize:36}}>{props.data.tracking_code}</h3></td>
                          </tr>
                            <tr>
                                <td> <h1></h1></td>
                             </tr>
                          </thead>
                        </table>
                      </TableCell>

                  </TableRow>
                </TableHead>
              </Table>
              </TableContainer>

              <div>



                  <Grid item xs={12} sm={13}>

                        <Grid container spacing={1}>

                              <Grid item xs={6}>
                                  <div style={{border:"1px solid #000", minHeight:281}}>
                                    <TableContainer>
                                      <Table size="small">
                                      <TableHead>
                                          <TableRow>
                                            <TableCell> <Typography variant="h6" align="left"> From </Typography> </TableCell>
                                            <TableCell align="right"></TableCell>
                                         </TableRow>
                                      </TableHead>
                                    </Table>
                                    </TableContainer>
                                    <TableContainer>

                                        <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className={classes.tableRow} >  <b> Name </b> </TableCell>
                                                <TableCell className={classes.tableRow} >
                                                <form  noValidate >
                                                <p>{props.myPackPrintFrom.first_name}{" "}{props.myPackPrintFrom.last_name}  </p>
                                                </form>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.tableRow} > </TableCell>
                                                <TableCell className={classes.tableRow} > </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.tableRow}  ><b> Phone Number </b>   </TableCell>
                                                <TableCell className={classes.tableRow} >
                                                <form  noValidate >
                                                  <p>{props.myPackPrintFrom.phone_number}</p>
                                                </form>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.tableRow} > </TableCell>
                                                <TableCell className={classes.tableRow} >  </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.tableRow} > <b> Address </b>  </TableCell>
                                                <TableCell className={classes.tableRow} >
                                                <form  noValidate >
                                                      <p>{props.myPackPrintFrom.address}</p>
                                                </form>
                                                </TableCell>

                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.tableRow} > </TableCell>
                                                <TableCell className={classes.tableRow} >  </TableCell>

                                            </TableRow>
                                        </TableBody>
                                        </Table>
                                        </TableContainer>
                                  </div>
                              </Grid>

                              <Grid item xs={6}>
                                  <div style={{border:"1px solid #000", minHeight:281}}>
                                    <TableContainer>
                                      <Table size="small">
                                      <TableHead>
                                          <TableRow>
                                            <TableCell> <Typography variant="h6" align="left"> To </Typography> </TableCell>
                                            <TableCell align="right"></TableCell>
                                        </TableRow>
                                      </TableHead>
                                    </Table>
                                    </TableContainer>

                                          <TableContainer>

                                              <Table size="small">
                                              <TableBody>

                                                  <TableRow>

                                                      <TableCell className={classes.tableRow} >  <b> Name </b> </TableCell>
                                                      <TableCell className={classes.tableRow} >
                                                      <form  noValidate >
                                                        <p>{props.myPackPrintTo.first_name}&nbsp;{props.myPackPrintTo.last_name} </p>
                                                      </form>
                                                      </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                      <TableCell className={classes.tableRow} > </TableCell>
                                                      <TableCell className={classes.tableRow} > </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                      <TableCell className={classes.tableRow}  ><b> Phone Number </b>   </TableCell>
                                                      <TableCell className={classes.tableRow} >
                                                      <form  noValidate >
                                                        <p>{props.myPackPrintTo.phone_number}</p>
                                                      </form>
                                                      </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                      <TableCell className={classes.tableRow} > </TableCell>
                                                      <TableCell className={classes.tableRow} >  </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                      <TableCell className={classes.tableRow} > <b> Address </b>  </TableCell>
                                                      <TableCell className={classes.tableRow} >
                                                      <form  noValidate >
                                                        <p>{props.myPackPrintTo.address}</p>
                                                      </form>
                                                      </TableCell>

                                                  </TableRow>
                                                  <TableRow>
                                                      <TableCell className={classes.tableRow} > </TableCell>
                                                      <TableCell className={classes.tableRow} >  </TableCell>

                                                  </TableRow>
                                              </TableBody>
                                              </Table>
                                              </TableContainer>
                                  </div>
                              </Grid>
                        </Grid>
                  </Grid>

                  <div style={{border:"1px solid #000",marginTop:15}}>
                    <TableContainer>
                      <Table size="small">
                      <TableHead>
                          <TableRow>
                            <TableCell> <Typography variant="h6" align="left"> Contains </Typography> </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      <TableRow>
                           {props.data.contains.map((data)=>{
                              return <TableRow><TableCell className={classes.tableRow}><li><span style={{minWidth:150,maxWidth:150,whiteSpace:'nowrap',fontSize:16 }}> {data} </span></li></TableCell></TableRow>
                            })}
                        </TableRow>
                      </TableBody>
                    </Table>
                    </TableContainer>
                  </div>
              </div>
              </div>
          </main>
          </div>
        </Fragment>
      );

  }

  export default compose(connect(mapStateToProps, mapDispatchToProps))(Inbox);
