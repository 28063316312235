import React from 'react';
import clsx from 'clsx';
import {fade, makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import Avatar from './Profile';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import SendIcon from '@material-ui/icons/Send';
import {Redirect} from 'react-router-dom'
import Dropdown from './ProfileDrpdwn'
import AddIcon from '@material-ui/icons/Add';
import {Link} from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ContactsIcon from '@material-ui/icons/PermContactCalendar';
import {LogOut} from '../../Actions/AuthAction';
import Alert from '../../common/Alert';

import {connect} from 'react-redux';
import {compose} from 'redux';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    backgroundColor: '#1976d2 ',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create([
      'width', 'margin'
    ], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create([
      'width', 'margin'
    ], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200
      }
    }
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  }
}));

const mapDispatchToProps = dispatch => ({
  LogOut: () => dispatch(LogOut())
});

const mapStateToProps = (state) => {
  return {loading: state.Common.loading,
          loggedIn: state.Auth.loggedIn,
          user: state.Auth.user,
          userlist: state.User.userlist
        }
}

export function MiniDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  console.log(props);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logOut = () => {
    props.LogOut();
  };

  return (<div className={classes.root}>
    <Alert/>
    <CssBaseline/>
    <AppBar position="fixed" className={clsx(classes.appBar, {
        [classes.appBarShift]: open
      })}>
      <Toolbar>
        <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" className={clsx(classes.menuButton, {
            [classes.hide]: open
          })}>
          <MenuIcon/>
        </IconButton>
        <Typography variant="h6" className={classes.title} noWrap="noWrap"></Typography>

        {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div> */
        }
      </Toolbar>
    </AppBar>
    <Drawer variant="permanent" className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })} classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}>
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          {
            theme.direction === 'rtl'
              ? <ChevronRightIcon/>
              : <ChevronLeftIcon/>
          }
        </IconButton>
      </div>

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{props.user.first_name}&nbsp;{props.user.last_name}</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Divider/> {/* <Button variant="text" color="primary">
        Creat New
      </Button> */
      }
      <Tooltip title="Add Packages">
        <ListItem component={Link} to={"/create"} selected={props.currentPage === "create"} button="button">
          <ListItemIcon>
            <IconButton size="small" aria-label="edit" style={{
                marginLeft: -3,
                backgroundColor: '#1976d2',
                color: '#ffffff'
              }} component={Link} to={"/create"}>
              <AddIcon fontSize="medium"/>
            </IconButton>
          </ListItemIcon>
          <ListItemText primary="Add Packages"/>
        </ListItem>
      </Tooltip>

      <Tooltip title="Dashboard">
        <ListItem component={Link} to={"/dashboard"} selected={props.currentPage === "dashboard"} button="button">
          <ListItemIcon>
            <DashboardIcon/>
          </ListItemIcon>
          <ListItemText primary="Dashboard"/>
        </ListItem>
      </Tooltip>

      {/*
        <Tooltip title="Sent">
          <ListItem component={Link} to={"/sent"} selected={props.currentPage === "sent"} button="button">
            <ListItemIcon>
              <SendIcon/>
            </ListItemIcon>
            <ListItemText primary="My Packages"/>
          </ListItem>
        </Tooltip>

        <Tooltip title="Received">
          <ListItem component={Link} to={"/inbox"} selected={props.currentPage === "inbox"} button="button">
            <ListItemIcon>
              <InboxIcon/>
            </ListItemIcon>
            <ListItemText primary="Received Packages"/>
          </ListItem>
        </Tooltip>
        */}


      {/*
        <Tooltip title="Archived">
        <ListItem component={Link} to={"/archived"} selected={props.currentPage==="archived"} button>
              <ListItemIcon>
                <ArchiveIcon />
              </ListItemIcon>
                  <ListItemText primary="Archived" />
        </ListItem>
          </Tooltip>
*/
      }
      {/*  <Tooltip title="E-Faxing">
        <ListItem button>
              <ListItemIcon>
                <ContactMailIcon />
              </ListItemIcon>
                  <ListItemText primary="E - Faxing" />
        </ListItem>
      </Tooltip> */
      }

      {/*   <Tooltip title="Reports">
        <ListItem button>
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
                  <ListItemText primary="Reports" />
        </ListItem>
          </Tooltip>*/
      }
      {
        props.user !== null && props.user !== undefined && (props.user.user_type == 1 || props.user.user_type == 2)
          ? <Tooltip title="Users">
              <ListItem component={Link} to={"/UserPackages"} selected={props.currentPage === "UserPackages"} button="button">
                <ListItemIcon>
                  <PeopleAltIcon/>
                </ListItemIcon>
                <ListItemText primary="Users"/>
              </ListItem>
            </Tooltip>
          : null
      }

      <Tooltip title="Contacts">
        <ListItem component={Link} to={"/ContactPackages"} selected={props.currentPage === "Contact"} button="button">
          <ListItemIcon>
            <ContactsIcon/>
          </ListItemIcon>
          <ListItemText primary="Contacts"/>
        </ListItem>
      </Tooltip>

      <Tooltip title="Logout" onClick={() => {
          logOut();
        }}>
        <ListItem selected={props.currentPage === "Logout"} button="button">
          <ListItemIcon>
            <ExitToAppIcon/>
          </ListItemIcon>
          <ListItemText primary="Logout"/>
        </ListItem>
      </Tooltip>

    </Drawer>

    {
      props.loggedIn === false
        ? <Redirect to="/"></Redirect>
        : null
    }
  </div>);
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(MiniDrawer);
