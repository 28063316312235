export default function reducer(state ={
    sentPackage:[],
    receivedPackage:[],
    myPackages:[],
    PrintPackageByID:{   id: 0,
                        from_contact_id: 0,
                        to_contact_id: 0,
                        user_id: 0,
                        package_contents:[],
                        tracking_code: null,
                        status:0,
                        archived: 0,
                        created_at: null,
                        updated_at: null,
                        contains: [],
                        qr_code: null,
                        trail: [],
                        from_contact:{
                                            id: 0,
                                            name: "",
                                            email: "",
                                            first_name: "",
                                            last_name: "",
                                            phone_number: "",
                                            address: "",
                                            user_type: 1,
                                            created_at: null,
                                            updated_at: null
                                    },
                        to_contact: {
                                                id: 0,
                                                name: "",
                                                email: "",
                                                first_name: "",
                                                last_name: "",
                                                phone_number: "",
                                                address: "",
                                                user_type: 1,
                                                created_at: null,
                                                updated_at: null
                                        }
                    } ,
    sentPackageByID:{   id: 0,
                        from_contact_id: 0,
                        to_contact_id: 0,
                        user_id: 0,
                        package_contents:[],
                        tracking_code: null,
                        status:0,
                        archived: 0,
                        created_at: null,
                        updated_at: null,
                        contains: [],
                        qr_code: null,
                        trail: [],
                        from_contact:{
                                            id: 0,
                                            name: "",
                                            email: "",
                                            first_name: "",
                                            last_name: "",
                                            phone_number: "",
                                            address: "",
                                            user_type: 1,
                                            created_at: null,
                                            updated_at: null
                                    },
                        to_contact: {
                                                id: 0,
                                                name: "",
                                                email: "",
                                                first_name: "",
                                                last_name: "",
                                                phone_number: "",
                                                address: "",
                                                user_type: 1,
                                                created_at: null,
                                                updated_at: null
                                        },},
    recivedPackageByID:{   id: 0,
                        from_contact_id: 0,
                        to_contact_id: 0,
                        user_id: 0,
                        package_contents:[],
                        tracking_code: null,
                        status:0,
                        archived: 0,
                        created_at: null,
                        updated_at: null,
                        contains: [],
                        qr_code: null,
                        trail: [],
                        from_contact:{
                                            id: 0,
                                            name: "",
                                            email: "",
                                            first_name: "",
                                            last_name: "",
                                            phone_number: "",
                                            address: "",
                                            user_type: 1,
                                            created_at: null,
                                            updated_at: null
                                    },
                        to_contact: {
                                                id: 0,
                                                name: "",
                                                email: "",
                                                first_name: "",
                                                last_name: "",
                                                phone_number: "",
                                                address: "",
                                                user_type: 1,
                                                created_at: null,
                                                updated_at: null
                                        }}
},action){
    console.log(action)
    switch(action.type){
        case "STORE_SENT_PACKAGES" : {
            return{
                ...state,sentPackage:action.package}
        }
        case "STORE_RECEIVED_PACKAGESS" : {
            return{
                ...state,receivedPackage:action.packageReceived
            }
        }
        case "STORE_RECEIVED_MY_PACKAGES" : {
            return{
                ...state,myPackages:action.package
            }
        }
        case "SHOW_INBOX_PACKAGE_DATA" : {
            return{
                ...state,sentPackageByID:action.Pack
            }
        }
        case "SHOW_RECIVED_PACKAGE_DATA" : {
            return{
                ...state,recivedPackageByID:action.Pack
            }
        }
        case "PRINT_PACKAGE_DATA" : {
            return{
                ...state,PrintPackageByID:action.Pack
            }
        }
        default:
            break;
    }
    return state;
}
