import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip'; 
import SearchIcon from '@material-ui/icons/Search';
import MaterialTable from 'material-table';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchContact,storeAddedContactTo } from '../../../Actions/ContactActions';


function mapStateToProps(state){
  console.log(state)
  return{
    loggedIn : state.Auth.loggedIn,
    contactlist:state.Contact.contactlist
  }
}

const mapDispatchToProps = dispatch => ({
  fetchContact: () => dispatch(fetchContact()),
  storeAddedContact: (data) => dispatch(storeAddedContactTo(data)),
  });

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),

  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function ToSearch(props) {
  const [open, setOpen] = React.useState(false);
  const classes = styles;

  if(props.contactlist!==undefined)
  {
        if(props.contactlist.length===0)
        {
         props.fetchContact();
        }
  }

  const handleClickClose = (event,rowData) => {
    handleClose() 
    props.storeAddedContact(rowData)
  };
 
  const [state, setState] = React.useState({
    
    columns: [
      { title: 'Name', field: 'first_name'},
      { title: 'Phone Number', field: 'phone_number' },  
      { title: 'Email', field: 'email' },
      { title: 'Address', field: 'address' },
    ],    
    
  });

  const handleClickOpen = () => {
    setOpen(true); 
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div >
      <Tooltip title="">
      <IconButton aria-label="edit" className={classes.extendedIcon} onClick={handleClickOpen}>
        <SearchIcon fontSize="small" />
      </IconButton>
      </Tooltip>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>

        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Contact Search
        </DialogTitle>

          <DialogContent dividers>
                          <MaterialTable
                            title=""
                            columns={state.columns}
                            data={props.contactlist}
                            onRowClick={(event, rowData) => handleClickClose(event, rowData)}
                                editable={{
                                  onRowAdd: newData =>
                                    new Promise(resolve => {
                                      setTimeout(() => {
                                        resolve();
                                        setState(prevState => {
                                          const data = [...prevState.data];
                                          data.push(newData);
                                          return { ...prevState, data };
                                        });
                                      }, 600);
                                    }),
                                  onRowUpdate: (newData, oldData) =>
                                    new Promise(resolve => {
                                      setTimeout(() => {
                                        resolve();
                                        if (oldData) {
                                          setState(prevState => {
                                            const data = [...prevState.data];
                                            data[data.indexOf(oldData)] = newData;
                                            return { ...prevState, data };
                                          });
                                        }
                                      }, 600);
                                    }),
                                  onRowDelete: oldData =>
                                    new Promise(resolve => {
                                      setTimeout(() => {
                                        resolve();
                                        setState(prevState => {
                                          const data = [...prevState.data];
                                          data.splice(data.indexOf(oldData), 1);
                                          return { ...prevState, data };
                                        });
                                      }, 600);
                                    }),
                                }}
                    />
          </DialogContent>

        <DialogActions>

        </DialogActions>
      </Dialog>
    </div>
  );
}


export default compose(connect(mapStateToProps, mapDispatchToProps))(ToSearch);