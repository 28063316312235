import React, { Props, Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { validateUser } from '../../Actions/AuthAction';
import { SnackBar } from '../../Actions/CommonActions';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '../../common/Alert';

import {Redirect} from 'react-router-dom'

const styles = theme => ({

    wrap:{
        width:480,
        margin:'auto',
        marginTop:100,
    },
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width:'98%'
      },

      button: {
        margin: theme.spacing.unit,
      },
      logoWrap:{
          textAlign:'center',
      }
  });

const mapStateToProps = (state) => {
    return {
      loggedIn : state.Auth.loggedIn
    }
}


const mapDispatchToProps = dispatch => ({
  validateUser : (username,password) => dispatch(validateUser(username,password)),
  SnackBar : (show,message,varient) => dispatch(SnackBar(show,message,varient))
});



class Login extends Component {

	constructor(props){
        super(props);

        this.state = {
            username:null,
            password:null
        };
    }

	componentDidMount(){
        document.body.style.background = "#eee";
    }

	handleChange = name => event => {
        this.setState({
          [name]: event.target.value,
        });
      };



    login(){
      console.log(this.state.username)
      if(this.state.username === null||this.state.username === ""){
          this.props.SnackBar(true,"We need your Email",'error');
          return false;
      }

      if(this.state.password === null||this.state.password === ""){
          this.props.SnackBar(true,"Password can not be empty",'error');
          return false;
      }
    this.props.SnackBar(true,"Signing you in",'info');
      this.props.validateUser(this.state.username,this.state.password);

  }

    render(){

		const { classes } = this.props;


        return <Fragment>
          <Alert/>
					<div className={classes.wrap} >
            <Paper className={classes.root} elevation={1}>
            <div className={classes.logoWrap} >
			{/*<img src={logo}/>*/}
            </div>
            <TextField
                    id="outlined-name"
                    label="Email"
                    className={classes.textField}
                    value={this.state.username}
                    onChange={this.handleChange('username')}
                    margin="normal"
                    variant="outlined"
                    />
            <TextField
                    id="outlined-name"
                    label="Password"
                    className={classes.textField}
                    type="password"
                    value={this.state.password}
                    onChange={this.handleChange('password')}
                    margin="normal"
                    variant="outlined"
                    />

                <Button  variant="contained" onClick={()=>{ this.login();}}  color="primary" className={classes.button} >
                    Login
                </Button>
            </Paper>
            </div>
            {this.props.loggedIn ? <Redirect to="/dashboard"></Redirect>:null}
                </Fragment> ;
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default compose(connect(mapStateToProps, mapDispatchToProps),withStyles(styles))(Login);
