import React, {Fragment } from 'react';
import Sidebar  from '../SideContent/SideBarIndex';
import Typography from '@material-ui/core/Typography';

import { compose } from 'redux';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Reply from './Common/reply';
import {fetchReceivedPackage} from '../../Actions/PackageActions'
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';

import Chip from '@material-ui/core/Chip';



const getStatus = (status) => {
  switch (status) {
    case 0: { return "Draft" }
    case 1: { return "Sent" }
    case 2: { return "In transit" }
    case 3: { return "Delivered" }
    case 4: { return "status 5" }
    default:break;
  }
}

const StatusStyle = (status) => {
  switch (status) {
    case 0: { return { backgroundColor: '#455A64', color: '#FFF' } }
    case 1: { return { backgroundColor: '#1976D2', color: '#FFF' } }
    case 2: { return { backgroundColor: '#FBC02D', color: '#FFF' } }
    case 3: { return { backgroundColor: '#388E3C', color: '#FFF' } }
    case 4: { return { backgroundColor: '#00695C', color: '#FFF' } }
    // case 5: { return { backgroundColor: '#f44336', color: '#FFF' } }
    default:break;
  }
}



const mapStateToProps = (state) => {
  console.log(state)
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
     packagelist:state.Package.receivedPackage
  }
}


const mapDispatchToProps = dispatch => ({
  fetchReceivedPackage: () => dispatch(fetchReceivedPackage()),
  });

const StatusChip = (status) => {
  return <div style={{width:75}}><Chip size="small" style={StatusStyle(status)} label={getStatus(status)} ></Chip></div>
}


class ReceivedPacakges extends React.Component{

  constructor(props) {
    super(props);


    this.state = {

    };
   this.props.fetchReceivedPackage();


  }


  render(){
        return (<Fragment>

          <div>
          <Sidebar currentPage={"inbox"}/>

            <main style={{paddingTop:100,paddingLeft:100}}>
              <TableContainer>
                <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell> <Typography variant="h4" align="left"> Received Packages</Typography> </TableCell>
                        <TableCell align="right" >
                            <Reply />
                             </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
              </TableContainer>
              <div>


              </div>
              <div />

              <TableContainer component={Paper} >
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Code</TableCell>
                        <TableCell>From</TableCell>
                        <TableCell>To</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell><span style={{ marginLeft:15 }} >Status</span></TableCell>
                        <TableCell></TableCell>


                      </TableRow>
                    </TableHead>

                    {this.props.packagelist !== undefined ? this.props.packagelist.map((row) => (

                      <TableRow  key={row}>
                      <TableCell>{row.tracking_code} </TableCell>
                      <TableCell>{row.from_contact!==null ?row.from_contact.first_name:null}</TableCell>
                      <TableCell>{row.to_contact!==null ?row.to_contact.first_name:null}</TableCell>
                      <TableCell>{row.created_at}</TableCell>
                      <TableCell>
                          {StatusChip(row.status)}
                      </TableCell>
                      <TableCell align="center" component={Link} to={"/inbox/request/"+row.id} >
                          <VisibilityIcon />
                      </TableCell >



                      </TableRow>
                          )):null}


                  </Table>
                </TableContainer>

            </main>
            </div>

          </Fragment>
        );

    }
}



  export default compose(connect(mapStateToProps,mapDispatchToProps))(ReceivedPacakges);
