import React from 'react'
import {BrowserRouter, Route, Switch,Redirect} from 'react-router-dom'
import Login from './Modules/Login';
import CreateNew from './Modules/CreateNew/Index';
import Print from './Modules/CreateNew/Common/Print';
import SentPackages from './Modules/MyPackages/Index'; 
import Inbox from './Modules/ReceivedPackages/Index';
import ReceivePackView from './Modules/ReceivedPackages/ReceivePackView';
import MyPack from './Modules/MyPackages/MyPackView'
import Dashboard from './Modules/DashBoard/dashboard';
import UserPackages from './Modules/UserManagement/Index';
import ContactPackages from './Modules/ContactManagement/Index';
import PrintMyPackage from './Modules/MyPackages/Common/printMyPack';
 
  
 
export default ()=>(
    <BrowserRouter basename="/">

        <Route path="/" exact component={Login}  />
        <Route path="/sent"  exact component={SentPackages} />
        <Route path="/inbox"  exact component={Inbox} />
        <Route path="/inbox/request/:id" extra component={ReceivePackView} />
        <Route path="/create" extrat component={CreateNew} />
        <Route path="/dashboard" extra component={Dashboard}/>
        <Route path="/print/show/:id" extra component={Print}/>
        <Route path="/MyPack/show/:id" extra component={MyPack}/>
        <Route path="/PrintMyPackage/show/:id" extra component={PrintMyPackage}/>
        <Route path="/UserPackages" extra component={UserPackages}/>
        <Route path="/ContactPackages" extra component={ContactPackages}/>

     </BrowserRouter>

);