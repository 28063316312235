import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link } from 'react-router-dom';
import EditContactButton from './EditButton';
import DeleteContactButton from './DeleteButton';
import Button from '@material-ui/core/Button';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { fetchContact,DeleteContact } from '../../../Actions/ContactActions';


const mapDispatchToProps = dispatch => ({
  fetchContact: () => dispatch(fetchContact()),
  DeleteContact: (id) => dispatch(DeleteContact(id)), 
});


const mapStateToProps = (state) => {
return {
  loading : state.Common.loading,
     loggedIn : state.Auth.loggedIn,
     contactlist:state.Contact.contactlist
}
}




class CSSGrid extends React.Component {

  constructor(props){
  super(props);

  this.state={
    fname:"",
    lname:"",
    phone:"",
    email:"",
    addrs:""


  }

  this.props.fetchContact();

  }

  handleChange = name => event => {
  console.log(this.state.categories)


  this.setState({
  [name]: event.target.value,
  });

}

handleDelete = (id) =>{


this.props.DeleteContact(id);


}


  render() {
  return (
  <  TableContainer >
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Address</TableCell>
              <TableCell></TableCell>
                <TableCell></TableCell>
                  <TableCell></TableCell>
            </TableRow>
          </TableHead>
        <TableBody>

         {this.props.contactlist !== undefined ? this.props.contactlist.map((row,index) => (
           <TableRow  key={row.id}>
           <TableCell>{index+1}</TableCell>
           <TableCell>{row.first_name}</TableCell>
           <TableCell>{row.last_name}</TableCell>
           <TableCell>{row.phone_number}</TableCell>
             <TableCell>{row.email}</TableCell>
           <TableCell>{row.address}</TableCell>
           <TableCell align="right"  >
             <EditContactButton   data={row}  />
           </TableCell>

           <TableCell align="left">

           <Button   >
              <DeleteContactButton handleDelete={()=>{this.handleDelete(row.id);}} />
           </Button>
           </TableCell >
           <TableCell >

             </TableCell>
         </TableRow>
         )):null}


        </TableBody>
        </Table>
        </TableContainer>

);
}
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(CSSGrid);
