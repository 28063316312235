export default function reducer(state ={
    users:[],
    userList:[],
    activePackage:{
        id: 0,
        from_contact_id: 0,
        to_contact_id: 0,
        package_contents: "",
        tracking_code: "",
        status: 4,
        archived: 0,
        created_at: null,
        updated_at: null,
        contains: [],
        qr_code: "",
        trail: [],
        from_contact: {
            id: 0,
            name: "",
            email: "",
            first_name: "",
            last_name: "",
            phone_number: "",
            address: "",
            user_type: 1,
            created_at: null,
            updated_at: null
       },
        to_contact:  {
            id: 0,
            name: "",
            email: "",
            first_name: "",
            last_name: "",
            phone_number: "",
            address: "",
            user_type: 1,
            created_at: null,
            updated_at: null
       }
    }
},action){
    console.log(action)


switch(action.type){
    case "STORE_USERS":{
        console.log(action.user)
    return{
        ...state,userList:action.user
        }
    }
    case "STORE_ADDED_USER_FROM":{
        return{
            ...state,userlistFrom:action.user
            }
        }
        case "STORE_ADDED_USER_TO":{
            return{
                ...state,userlistTo:action.user
                }
            }

        case "STORE_ADDED_CONTACT_FROM" :{
            return{
                ...state,
                    activePackage:{
                    ...state.activePackage,
                        from_contact:action.From

                }
            }
        }
        case "STORE_ADDED_CONTACT_TO" :{
            return{
                ...state,
                    activePackage:{
                    ...state.activePackage,
                             to_contact:action.To

                }
            }
        }

        case "STORE_PACKAGE_ALL_DATA" :{
            return{
                ...state,
                    activePackage:{
                    ...state.activePackage,
                            contains:action.all
                }
            }
        }

        case "All_PACKAGE_DATA" :{
            return{
                ...state,
                    activePackage:{
                    ...state.activePackage,
                            tracking_code:action.Pack.tracking_code,
                            package_contents:action.Pack.package_contents,
                            id:action.Pack.id
                }
            }
        }

        case "SHOW_PACKAGE_DATA" :{
            return{
                ...state,
                    activePackage:{
                    ...state.activePackage,
                            from_contact:action.Pack.from_contact,
                            to_contact:action.Pack.to_contact,
                            tracking_code:action.Pack.tracking_code,
                            contains:action.Pack.contains,
                            id:action.Pack.id,
                            qr_code:action.Pack.qr_code
                           }
            }
        }

        default:
          break;
}

return state;
}
