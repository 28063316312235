import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
//import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import DeleteConfermation from  './DeleteConfermation';   
import { SnackBar } from '../../../Actions/CommonActions';
import { compose } from 'redux';
import { connect } from 'react-redux';

import DeleteIcon from '@material-ui/icons/Delete';



const styles = theme => ({ 
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


function mapStateToProps(state){
  return{
  }
}

const mapDispatchToProps = dispatch => ({
  SnackBar : (show,message,varient) => dispatch(SnackBar(show,message,varient))
});

function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const handleDelete = () => {
    handleClose()
    props.SnackBar(true,"Deleting user",'success');
    props.handleDelete()
  };

  return (
    <div>

      <DeleteIcon onClick={handleClickOpen}/>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} >
          Delete Details
        </DialogTitle>



        <DialogContent dividers>
        {/* <FormControl componentClass="textarea" style={{ height: 200 }} /> */}

       <DeleteConfermation />

    

        </DialogContent>
        <DialogActions>
          <Button autoFocus  color="primary" onClick={handleDelete}>
                DELETE 
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(CustomizedDialogs);
