import React, {Fragment } from 'react';

import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import {fetchPackage,PackageStatusSent} from '../../Actions/PackageActions'
import Button from '@material-ui/core/Button';


const getStatus = (status) => {
  switch (status) {
    case 0: { return "Draft" }
    case 1: { return "Sent" }
    case 2: { return "In transit" }
    case 3: { return "Delivered" }
    // case 4: { return "status 5" }
    default:break;
  }
}

const StatusStyle = (status) => {
  switch (status) {
    case 0: { return { backgroundColor: '#455A64', color: '#FFF' } }
    case 1: { return { backgroundColor: '#1976D2', color: '#FFF' } }
    case 2: { return { backgroundColor: '#FBC02D', color: '#FFF' } }
    case 3: { return { backgroundColor: '#388E3C', color: '#FFF' } }
    // case 4: { return { backgroundColor: '#00695C', color: '#FFF' } }
    // case 5: { return { backgroundColor: '#f44336', color: '#FFF' } }
    default:break;
  }
}



const mapStateToProps = (state) => {
  console.log(state)
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
     packagelist:state.Package.sentPackage
  }
}


const mapDispatchToProps = dispatch => ({
      fetchPackage: () => dispatch(fetchPackage()),
      PackageStatusSent:(id)=>dispatch(PackageStatusSent(id))
  });

const StatusChip = (status) => {
  return <div style={{width:75}}><Chip size="small" style={StatusStyle(status)} label={getStatus(status)} ></Chip></div>
}


class SentPackages extends React.Component{

  constructor(props) {
    super(props);


    this.state = {

    };
   this.props.fetchPackage();


  }


  render(){
        return (<Fragment>

          <div>



            <main>
              <div />
              <TableContainer component={Paper} >
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Code</TableCell>
                        <TableCell>From</TableCell>
                        <TableCell>To</TableCell>
                        <TableCell width="200px">Date</TableCell>
                        <TableCell style={{ textAlign:"-webkit-center" }}><span >Status</span></TableCell>
                        <TableCell></TableCell>


                      </TableRow>
                    </TableHead>

                    {this.props.packagelist !== undefined ? this.props.packagelist.map((row) => (

                      <TableRow  key={row}>
                      <TableCell>{row.tracking_code} </TableCell>
                      <TableCell>{row.from_contact!==null ?row.from_contact.first_name:null}</TableCell>
                      <TableCell>{row.to_contact!==null ?row.to_contact.first_name:null}</TableCell>
                      <TableCell>{row.created_at}</TableCell>
                      <TableCell style={{ textAlign:"-webkit-center" }}>
                          {StatusChip(row.status)}
                      </TableCell>
                      <TableCell align="right"  >

                            <Button size="small" variant="contained" onClick={()=>this.props.PackageStatusSent(row.id)}>
                              Sent
                            </Button>&nbsp;
                            <Button size="small" variant="contained" component={Link} to={"/MyPack/show/"+row.id}>
                              View
                            </Button>
                      </TableCell >

                      {/* <TableCell align="center" component={Link}>
                          <ButtonSetSend />
                            </TableCell > */}

                      </TableRow>
                          )):null}


                  </Table>
                </TableContainer>

            </main>
            </div>

          </Fragment>
        );

    }
}



  export default compose(connect(mapStateToProps,mapDispatchToProps))(SentPackages);
