import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { compose } from 'redux';
import { connect } from 'react-redux'; 
import {recievedPackage} from '../../../Actions/PackageActions';
import { SnackBar } from '../../../Actions/CommonActions';
import { TextField } from '@material-ui/core';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: { 
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function mapStateToProps(state){ 
  return{
    data:state.User.activePackage,
    packagelist:state.Package.receivedPackage
  }
}

const mapDispatchToProps = dispatch => ({
  recievedPackage: (data) => dispatch(recievedPackage(data)),
  SnackBar : (show,message,varient) => dispatch(SnackBar(show,message,varient))
  });

 function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);
  const [code,setCode]=React.useState("")

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDialog = () => {
    if(code==="")
              {
                props.SnackBar(true,"Tracking Code Can Not Be Empty",'error');
                return false
              }
    for (var x = 0; x < props.packagelist.length; x++) 
         { 
           if(code===props.packagelist[x].tracking_code)
              {
                props.SnackBar(true,"Tracking Code Already Included",'error');
                return false
              }
         }
    setOpen(false);
    props.SnackBar(true,"Updating package",'success');
    props.recievedPackage({code})
  };

  return (
    <div>
      <Button size="small" variant="contained" color="primary" onClick={handleClickOpen}>
        Receive Package
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Tracking Code
        </DialogTitle>
        <DialogContent dividers>
        {/* <FormControl componentClass="textarea" style={{ height: 200 }} /> */}
        <DialogActions>
        <DialogActions>
       <TextField value={code}  onChange={(e)=>setCode(e.target.value)}  style={{ height: 40 , width: 350, FontSize:80 }} placeholder="" />
       </DialogActions>
       </DialogActions>
        </DialogContent>
        <DialogActions>
          <Button autoFocus  color="primary" onClick={()=>handleCloseDialog()} >
          Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(CustomizedDialogs);