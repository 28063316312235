import { applyMiddleware, createStore, combineReducers } from 'redux';

import logger from 'redux-logger'
import thunk from 'redux-thunk';
import promise from "redux-promise";


import { loadAuthState, saveAuthState } from './Modules/Auth/AuthSession';

import reducers from "./Reducers/index"

import throttle from 'lodash/throttle';


const middleware = applyMiddleware(promise, thunk, logger)

const store = createStore(reducers, { Auth: loadAuthState() }, middleware);

store.subscribe(throttle(() => {
    saveAuthState({
        Auth: store.getState().Auth
    });

}, 1000));

export default store;
