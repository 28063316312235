import React, {Fragment, useState } from 'react';
import Sidebar  from '../SideContent/SideBarIndex';

import {makeStyles,withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import UserList from './Common/UserListTable';



import AddUserButton from './Common/AddButton';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { fetchUser, DeleteUser } from '../../Actions/UserActions';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    alignItems: 'center',
    display: 'container',
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  }));

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUser()),
   DeleteUser: (id) => dispatch(DeleteUser(id)),
});

const mapStateToProps = (state) => {
return {
  loading : state.Common.loading,
     loggedIn : state.Auth.loggedIn,
     userlist:state.User.userlist
}
}

class CSSGrid extends React.Component {

  componentDidMount = () =>{
        this.props.fetchUser();
  }
  componentDidMount = () =>{
        this.props.DeleteUser();
  }

  constructor(props){
  super(props);

  this.state={
    fname:"",
    lname:"",
    phone:"",
    email:"",
    addrs:""


  }

  this.props.fetchUser();
}

handleChange = name => event => {
console.log(this.state.categories)


this.setState({
[name]: event.target.value,
});

}

handleDelete = (id) =>{
  if(id!==undefined){
    this.props.DeleteUser(id);
  }
}


render() {

  const {classes} = this.props;



    return (<Fragment>



      <div className={classes.root}>
          <Sidebar currentPage={"UserPackages"}/>

           <main className={classes.content} style={{paddingTop:100,paddingLeft:100}}>
             <div className={classes.toolbar} />
               <TableContainer>
                 <Table size="small">
                 <TableHead>
                     <TableRow>

                     <TableCell className={classes.tableRow}> <Typography variant="h4" align="left">Users</Typography></TableCell>
                      <TableCell className={classes.tableRow} align="right" > <AddUserButton/> </TableCell>

                   </TableRow>
                 </TableHead>
               </Table>
               </TableContainer>
               <div>

                   {/* TWO SIDE  */}
                   <Grid container spacing={1}>
                     {/* LEFT */}
                   <Grid item xs={12} sm={8}>
                     {/* TWO PAGES */}
                         <Grid container spacing={1}>
                           {/* PAGE 1  DemographyTable*/}
                               <Grid item xs={6}>


                               </Grid>
                         </Grid>
                   </Grid>

                 </Grid>
               </div>
               <UserList/>

           </main>
           </div>
           </Fragment>

  );
}
}

export default compose(withStyles(useStyles),connect(mapStateToProps, mapDispatchToProps))(CSSGrid);
