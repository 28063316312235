import { combineReducers } from 'redux';

import Auth from '../Modules/Auth/AuthReducer';
 import Common from './CommonReducer';
import Package from './PackageReducer';
import User from './UserReducer';
import Contact from './ContactReducer';


export default combineReducers({
Auth,
Common,
Package,
Contact,
User,
})
