import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';




const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
  toolbar: {
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  container: {
    display:'container',
    marginLeft:290,
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    whiteSpace: 'wrap',
    height:150, 
    width:150,
  },
  divider: {
  margin: theme.spacing(2, 0),
  }, 
  }));


export default function FixedContainer() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <Container fixed style={{  backgroundColor:'#F2F3F4',marginTop:113}}>
        <div>
          <Typography variant="h3" style={{ marginLeft:290 }}> Delivery Tracking System</Typography>
        </div>

        <div className={classes.root}>

          <main className={classes.content} >

          <div className={classes.toolbar} />
              <div  className={classes.container}>
                  <Grid container spacing={3} 
                    >
                      <Grid item xs={12} sm={4}>
                          <Button
                          topIcon={  <AddIcon style={{ fontSize: 80 ,}} />}
                            component={Link} to={"/create"}
                            className={classes.paper}
                            variant="outlined"
                            color="primary">
                            <AddIcon style={{ fontSize: 80 ,}} />
                          </Button>
                        <div>
                        <Typography variant="h6" align="center"> Create new  </Typography>
                        </div>
                      </Grid>


                      <Grid item xs={12} sm={4}>
                        <Button
                          component={Link} to={"/inbox"}
                          className={classes.paper}
                          variant="outlined"
                          color="primary">
                          <InboxIcon style={{ fontSize: 80 ,}} />
                        </Button>
                      <Typography variant="h6" align="center"> Inbox </Typography>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Button
                          component={Link} to={"/sent"}
                          className={classes.paper}
                          variant="outlined"
                          color="primary">
                          <SendIcon style={{ fontSize: 80 ,}} />
                        </Button>

                        <Typography variant="h6" align="center"> Sent </Typography>

                      </Grid>
                </Grid>
              </div>
              <div className={classes.container}>


              </div>

          </main>
          </div>

      </Container>
    </React.Fragment>
  );
}
