import React from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { TableBody } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchContact,updateContact} from '../../../Actions/ContactActions';
import { SnackBar } from '../../../Actions/CommonActions';
import Button from '@material-ui/core/Button';

const mapDispatchToProps = dispatch => ({
  fetchContact: () => dispatch(fetchContact()),
  updateContact: (data,id) => dispatch(updateContact(data,id)),
  SnackBar : (show,message,varient) => dispatch(SnackBar(show,message,varient))
});

const mapStateToProps = (state) => {
return {
  loading : state.Common.loading,
  loggedIn : state.Auth.loggedIn,
  contactlist:state.User.contactlist
}
}

const useStyles = makeStyles(theme => ({

  tableRow: {
    borderBottom: "none"
 },
 
}));



 class EditContact extends React.Component {

 constructor(props){
 super(props);
//const { data } = this.props;
 this.state={
   first_name:this.props.data.first_name,
   last_name:this.props.data.last_name,
   phone:this.props.data.phone_number,
   email:this.props.data.email,
   addrs:this.props.data.address


 }
}

 handleChange = name => event => {
 console.log(event.target.value)


 this.setState({
 [name]: event.target.value,
 });

}

componentDidMount(){
  this.setState({
    fname:this.props.data.first_name,
    lname:this.props.data.last_name,
    phone:this.props.data.phone_number,
    email:this.props.data.email,
    addrs:this.props.data.address

  })
}

handleEdit = () =>{
  this.props.SnackBar(true,"Updating contact",'success');
  this.props.updateContact(
    {
      id:this.props.data.id,
      first_name:this.state.first_name,
      last_name:this.state.last_name,
      phone_number:this.state.phone,
      email:this.state.email,
      address:this.state.addrs

    }
  );
}


handlePop = () =>{

  this.handleEdit();
  this.props.handleClose()
  }


render() {
console.log(this.state.phone)
  return (
<div>
    <TableContainer>
        <Table size="small">
        <TableBody>

        <TableRow>
            <TableCell  >  <b> First Name</b> </TableCell>
            <TableCell  >
            <form  noValidate >
                <TextField id="outlined-basic" value={this.state.first_name} onChange={this.handleChange('first_name')}  label="First Name"  variant="outlined"size="small" />
            </form>
            </TableCell>
        </TableRow>

        <TableRow>
            <TableCell  >  <b> Last Name</b> </TableCell>
            <TableCell  >
            <form  noValidate >
                <TextField id="outlined-basic" value={this.state.last_name}  onChange={this.handleChange('last_name')}  label="Last Name" variant="outlined"size="small" />
            </form>
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell  > </TableCell>
            <TableCell  > </TableCell>
        </TableRow>
        <TableRow>
            <TableCell  ><b> Phone Number </b>   </TableCell>
            <TableCell  >
            <form  noValidate >
                <TextField id="outlined-basic" value={this.state.phone} onChange={this.handleChange('phone')}  label="Phone Number " variant="outlined"size="small" />
            </form>
             </TableCell>
        </TableRow>
        <TableRow>
            <TableCell  > </TableCell>
            <TableCell >  </TableCell>
        </TableRow>
        <TableRow>
            <TableCell  >  <b> Email</b> </TableCell>
            <TableCell  >
            <form  noValidate >
                <TextField id="outlined-basic" value={this.state.email} onChange={this.handleChange('email')} label="Email" variant="outlined"size="small" />
            </form>
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell > <b> Address </b>  </TableCell>
            <TableCell  >
            <form  noValidate >
                <TextField id="outlined-basic" value={this.state.addrs} onChange={this.handleChange('addrs')} label="Address" variant="outlined"size="small" />
            </form>
            </TableCell>

        </TableRow>
        </TableBody>
        </Table>
        </TableContainer>

        <Button autoFocus align="right" color="primary" onClick={()=>{this.handlePop();}} >
        Save
        </Button>
</div>
);
}
}
 export default compose(connect(mapStateToProps, mapDispatchToProps))(EditContact);
