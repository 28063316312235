import React, {Fragment, useState } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { TableBody } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import { compose } from 'redux';
import { connect } from 'react-redux'; 
import { SnackBar } from '../../Actions/CommonActions';

import { storePackageAllData } from '../../Actions/ContactActions';
import {addPackage} from '../../Actions/PackageActions'


import SearchFrom from './Common/ContactSearchFrom';
import SearchTo from './Common/ContactSearchTo'; 

import Sidebar  from '../SideContent/SideBarIndex';



const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    alignItems: 'center',
    display: 'container',
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
    height:260,
  },
  paperRight: {
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    height:585,
  },
  paperBottom: {
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary, //change discription color
    height:290,
    marginTop:27,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  tableRow: {
    borderBottom: "none"
 },
  button: {
    background: 'linear-gradient( #1976d2  , #1976d2  )',
    // backgroundColor:'linear-gradient( #1976d2  , #1976d2  )',
    color:'#ffffff',
    margin: theme.spacing(1),
  },
  }));

function mapStateToProps(state){
  return{
    data:state.User.activePackage,
    ActiveFrom:state.User.activePackage.from_contact,
    ActiveTo:state.User.activePackage.to_contact
  }
}

const mapDispatchToProps = dispatch => ({
  storePackageAllData: (data) => dispatch(storePackageAllData(data)),
  addPackage: (data) => dispatch(addPackage(data)),
  SnackBar : (show,message,varient) => dispatch(SnackBar(show,message,varient))
  });


  function Create(props) {
    const classes = useStyles();
    const [arrayContent, setContent] = React.useState([]);
    const [arrayContentLength, setContentLength] = React.useState(arrayContent.length);

    const [toggle,setToggle] = useState( 'false' );
    const [value, setValue] = React.useState([]);


    function handleClick(e) {
      let curContent = arrayContent;
      setContent([
        ...arrayContent,
        value
      ]);
      setContentLength(arrayContent.length);
      setValue("");



    }

    React.useEffect(() => {
        if(arrayContent.length !== arrayContentLength){
            props.storePackageAllData(arrayContent);
            setContentLength(arrayContent.length);
        }
    });

    function handleChange(e) {
      setValue(e.target.value)
    }

    function packageSave(){
      
      console.log(props.data.contains);
      
      if(props.data.from_contact.id === 0){
        props.SnackBar(true,"Please select sender contact",'error');
        return false
      }

      
      if(props.data.to_contact.id === 0){
        props.SnackBar(true,"Please select receiver contact",'error');
        return false
      }

      
      if(props.data.contains.length === 0){
        props.SnackBar(true,"Please include package contains",'error');
        return false
      }

      if(props.data.from_contact.id !== 0 && props.data.to_contact.id !== 0 ){
        props.SnackBar(true,"Creating your package",'success');
        setToggle(true);
         props.addPackage({
           id:props.data.id,
           from_contact_id:props.data.from_contact.id,
           to_contact_id:props.data.to_contact.id,
           package_contents:props.data.contains

                   })
      }
    }



      return (<Fragment>

        <div className={classes.root}>
          <Sidebar currentPage={"create"}/>
          <main className={classes.content}>
            <div className={classes.toolbar} />
              <TableContainer>
                <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableRow}> <Typography variant="h4" align="left"> Create Package</Typography> </TableCell>
                        <TableCell className={classes.tableRow} align="right" onClick={()=>packageSave(props.data)} >
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    endIcon={<Icon>save</Icon>}
                                >
                                    Save
                                </Button>
                             </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
              </TableContainer>
              <div>

                  <Grid container spacing={1}>

                  <Grid item xs={12} sm={8}>

                        <Grid container spacing={1}>

                              <Grid item xs={6}>
                                  <Paper className={classes.paper}>
                                    <TableContainer>
                                      <Table size="small">
                                      <TableHead>
                                          <TableRow>
                                            <TableCell> <Typography variant="h6" align="left"> From </Typography> </TableCell>
                                            <TableCell align="right"> <SearchFrom /> </TableCell>
                                        </TableRow>
                                      </TableHead>
                                    </Table>
                                    </TableContainer>
                                                        
                                          <TableContainer>
                                              <Table size="small">
                                              <TableBody>
                                                  <TableRow>
                                                      <TableCell className={classes.tableRow} >  <b> Name </b> </TableCell>
                                                      <TableCell className={classes.tableRow} >
                                                      <form  noValidate >

                                                          <p>{props.ActiveFrom.first_name}{" "}{props.ActiveFrom.last_name}</p>
                                                      </form>
                                                      </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                      <TableCell className={classes.tableRow} > </TableCell>
                                                      <TableCell className={classes.tableRow} > </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                      <TableCell className={classes.tableRow}  ><b> Phone Number </b>   </TableCell>
                                                      <TableCell className={classes.tableRow} >
                                                      <form  noValidate >
                                                          <p> {props.ActiveFrom.phone_number} </p>
                                                      </form>
                                                      </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                      <TableCell className={classes.tableRow} > </TableCell>
                                                      <TableCell className={classes.tableRow} >  </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                      <TableCell className={classes.tableRow} > <b> Address </b>  </TableCell>
                                                      <TableCell className={classes.tableRow} >
                                                      {props.ActiveFrom.address} 
                                                      </TableCell>

                                                  </TableRow>
                                                  <TableRow>
                                                      <TableCell className={classes.tableRow} > </TableCell>
                                                      <TableCell className={classes.tableRow} >  </TableCell>
                                                  </TableRow>
                                              </TableBody>
                                              </Table>
                                              </TableContainer>

                                  </Paper>
                              </Grid>

                              <Grid item xs={6}> 
                                  <Paper className={classes.paper}>
                                    <TableContainer>
                                      <Table size="small">
                                      <TableHead>
                                          <TableRow>
                                            <TableCell> <Typography variant="h6" align="left"> To </Typography> </TableCell>
                                            <TableCell align="right">  <SearchTo /> </TableCell>
                                        </TableRow>
                                      </TableHead>
                                    </Table>
                                    </TableContainer> 

                                                        <TableContainer>
                                                            <Table size="small">
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell className={classes.tableRow} >  <b> Name </b> </TableCell>
                                                                    <TableCell className={classes.tableRow} >
                                                                    <form  noValidate >

                                                                        <p>{props.ActiveTo.first_name}{" "}{props.ActiveTo.last_name}</p>
                                                                    </form>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell className={classes.tableRow} > </TableCell>
                                                                    <TableCell className={classes.tableRow} > </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell className={classes.tableRow}  ><b> Phone Number </b>   </TableCell>
                                                                    <TableCell className={classes.tableRow} >
                                                                    <form  noValidate >
                                                                        <p> {props.ActiveTo.phone_number} </p>
                                                                    </form>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell className={classes.tableRow} > </TableCell>
                                                                    <TableCell className={classes.tableRow} >  </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell className={classes.tableRow} > <b> Address </b>  </TableCell>
                                                                    <TableCell className={classes.tableRow} >
                                                                    {props.ActiveTo.address} 
                                                                    </TableCell>

                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell className={classes.tableRow} > </TableCell>
                                                                    <TableCell className={classes.tableRow} >  </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                            </Table>
                                                            </TableContainer>

                                  </Paper>
                              </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper className={classes.paperBottom}>
                              <TableContainer>
                                <Table size="small">
                                <TableHead>
                                    <TableRow>
                                      <TableCell> <Typography variant="h6" align="left">  Tracking Code </Typography> </TableCell> 
                                      <TableCell align="right"></TableCell>
                                  </TableRow>
                                </TableHead>
                              </Table>
                              </TableContainer>

                                <Typography variant="h3" style={{ padding:40, textAlign:"center",   }}>
                                {toggle===true?<div>{props.data.tracking_code}</div>:null}
                                </Typography>
                                <Typography variant="h3" style={{ padding:20, marginTop:2, textAlign:"right",   }}>
                                {toggle===true?<div >{ props.data.tracking_code !==null ? (
                                     <Button
                                        variant="contained"
                                        className={classes.button}
                                        href={`./print/show/`+props.data.id}  target={`./print/show/`+props.data.id} 
                                    >
                                        Print Lable
                                   </Button>):null}</div>:null}
                                </Typography>

                              <TableContainer style={{ marginTop:35 }} >
                                <Table size="small">
                                <TableHead>
                                    <TableRow>
                                      <TableCell className={classes.tableRow}> </TableCell>
                                      <TableCell className={classes.tableRow} align="right">  </TableCell>
                                  </TableRow>
                                </TableHead>
                              </Table>
                              </TableContainer>
                          </Paper>
                        </Grid>
                  </Grid>


                  <Grid item xs={12} sm={4}>
                    <Paper className={classes.paperRight}>
                      <TableContainer>
                        <Table size="small">
                        <TableHead>
                            <TableRow>
                              <TableCell> <Typography variant="h6" align="left"> Contains </Typography> </TableCell>
                              <TableCell align="right">
                                <label>
                                  <input  onChange={handleChange} type="text" value={value} name="name" />
                                  <button onClick={handleClick} >
                                    Add
                                  </button>
                                </label>
                              </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {arrayContent.map((item)=>{
                           return  <TableRow><TableCell size="medium" ><span style={{minWidth:150,maxWidth:150,whiteSpace:'nowrap',fontSize:16 }}>{item} </span> </TableCell><TableCell align="right">  </TableCell></TableRow>;
                          })}
                        </TableBody>
                      </Table>
                      </TableContainer>
                    </Paper>
                  </Grid>
                </Grid>
              </div>

          </main>
          </div>

        </Fragment>
      );

  }

  export default compose(connect(mapStateToProps, mapDispatchToProps))(Create);
