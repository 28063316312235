import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import AddPopup from './AddContactPopup';

const useStyles = makeStyles(theme => ({
  button: {
    background: 'linear-gradient( #1976d2  , #1976d2  )',
    // backgroundColor:'linear-gradient( #1976d2  , #1976d2  )',
    color:'#ffffff',
    margin: theme.spacing(1),
  },
}));

export default function IconLabelButtons() {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{display:'flex', float:'right'}}>

        <AddPopup/>
    </div>
  );
}
