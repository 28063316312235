export default function reducer(state={
contactlist:[],
contactlistFrom:[],
contactlistTo:[],
content:[]

},action){
    console.log(action)
switch(action.type){
    case "STORE_CONTACT":{ 
    return{
        ...state,contactlist:action.con
        }
    }
    case "STORE_ADDED_CONTACT_FROM":{
        return{
            ...state,contactlistFrom:action.con
            }
        }
    
        case "STORE_ADDED_CONTACT_FROM_FULL":{
            return{
                ...state,contactlist:action.con
                }
            }

    case "STORE_ADDED_CONTACT_TO":{
        return{
            ...state,contactlistTo:action.con
            }
        }
    case "STORE_CONTENT":{
        return{
            ...state,content:action.content
            }
        }    

    default:
        break;
}

return state;
}
