import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import {startLoading,endLoading, SnackBar,handleHTTPError} from './CommonActions';
import store from '../Store';




export function validateUser(username,password){
    return function (dispatch){

        dispatch(startLoading());

        fetch(API_URL+'oauth/token',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },

            body :JSON.stringify({
                'client_id' :API_CLIENT_ID,
                'grant_type' :API_GRANT_TYPE,
                'client_secret':API_CLIENT_SECRET,
                'username':username,
                'password': password

            })
        }).then(handleHTTPError)
          .then((response)=>{
                dispatch(endLoading());
                response.json().then((json)=>{
                    dispatch(SnackBar(true,'Login successful','success'));
                    dispatch(storeAuthToken(json));
                    dispatch(fetchUser());
                });
          }).catch((error)=>{
            dispatch(endLoading());
            dispatch(SnackBar(true,'Invalid login information','error'));
          });
    }

}
export function storeAuthToken(authToken){
  console.log(authToken)
    return{type:'STORE_TOKEN',token:authToken};
}
export function LogOut(){
    return{type:'USER_LOGOUT'};
}

export function fetchUser(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'api/user',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' : 'Bearer '+store.getState().Auth.authToken.access_token
            }
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{

                  dispatch(storeUser(json));
                  dispatch(endLoading());
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Error fetching data','error'));
        });

    }
}
export function storeUser(user){
    return{type:'STORE_USER',user:user};
}

export function updateUser(data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/supplier/profile',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(fetchUser(json));
                  dispatch(SnackBar(true,'Your profie was updated','success'));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not update profile','error'));
        });
    }

}

export function storeUsers(list) {
    return {
        type: 'STORE_USERS',
        users: list
    }
}

export function selectUser(user) {
    return {
        type: 'USER_SELECTED',
        user: user
    }
}

export function logoutUser() {
    return {
        type: 'USER_LOGOUT',

    }
}

export function Logout() {
    return function (dispatch) {
        dispatch(logoutUser());
    }

}

//export function uploadWorkLogo(file){

  //  return function(dispatch) {
  //      dispatch(SnackBar(true,'Uploading image, please wait...','info'));

//var fd = new FormData();
    //    fd.append('file',file,file.name)
//
    //    axios.post(API_URL+'/api/supplier/profile/logo/work',fd,{
      //      headers: {
    //          'Content-Type': 'application/json;charset=utf-8',
      //        'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
//},
  //      }).then((response)=>{
    //          dispatch(endLoading());
    //          dispatch(fetchUser());
      //        dispatch(SnackBar(true,'Your profie was updated','success'));
//}).catch((error)=>{
      //    dispatch(endLoading());
    //      dispatch(SnackBar(true,'Could upload image','error'));
//});
  //  }
//}

export function lockUserData(){
    return{type:'LOCK_USER_DATA'};
}


//export function uploadPersonalLogo(file){

  //  return function(dispatch) {
  //      dispatch(SnackBar(true,'Uploading image, please wait...','info'));
//
    //    var fd = new FormData();
    //    fd.append('file',file,file.name)

    //    axios.post(API_URL+'/api/supplier/profile/logo/personal',fd,{
      //      headers: {
      //        'Content-Type': 'application/json;charset=utf-8',
        //      'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
      //      },
  //      }).then((response)=>{
      //        dispatch(endLoading());
    //          dispatch(fetchUser());
      //        dispatch(SnackBar(true,'Your profie was updated','success'));
  //      }).catch((error)=>{
    //        console.log(error)
    //      dispatch(endLoading());
        //  dispatch(SnackBar(true,'Could upload image','error'));
    //    });
  //  }
//}

//export function uploadDocument(file,type){

    //return function(dispatch) {
      //  dispatch(SnackBar(true,'Uploading, please wait...','info'));

//var fd = new FormData();
      //  fd.append('file',file,file.name);
    //    fd.append('type',type);

      //  axios.post(API_URL+'/api/supplier/profile/document',fd,{
      //      headers: {
        //      'Content-Type': 'application/json;charset=utf-8',
          //    'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
        //    },
//}).then((response)=>{
      //        dispatch(endLoading());
      //        dispatch(fetchUser());
      //        dispatch(SnackBar(true,'Document updated successfully','success'));
  //      }).catch((error)=>{
        //    console.log(error)
  //        dispatch(endLoading());
  //        dispatch(SnackBar(true,'Could upload image','error'));
    //    });
  //  }
//}
